@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.progress_box {
    width: 100%;
    &__title {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 500;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
    .state_container {
        justify-content: space-between;
    }
    .state_icon {
        text-align: center;
        width: 25%;
        padding: 0.4em;
    }
}

.main_content_container {
    width: 100%;
    .tfa_text_wrapper1{
        margin-top: 2em;
    }
    .primary_txt1 {
        font-size: 1.5em;
    }
    .secondary_txt1 {
        width: 80%;
        font-size: 2.5em;
        color: $font_blue;
    }
    .tfa_sub_text_primary {
        margin-top: 2em;
    }
}

.title_box {
    margin: 1.8em 0 0.8em 0;
    width: 100%;
    &__primary {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        font-weight: 400;
        margin: 0.3em 0;
        width: 75%;
        @include breakpoint(lg) {
            width: 90%;
        }
        &__bold {
            font-weight: 700;
        }
    }
    &__simple_input {
        width: 100%;
        padding-top: 1em;
    }
}

.professional_select_btns {
    width: 100%;
    margin-top: 0.5em;
    padding-bottom: 4em;
    .tfa_button_container1 {
        height: 3em;
        background: $bg-white;
        color: $font_light_blue;
        border: 2px solid $border-blue;
        margin-bottom: 0.3em;
        &:hover {
            background: $bg-white;
        }
    }
    .tfa_button_container2 {
        height: 3em;
        background: $btn-light;
        margin-top: 0.3em;
        &:hover {
            background: $btn-light;
        }
    }
    &__remove_btn {
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 1.2em;
        width: 100%;
        margin: 0.7em 0;
        font-weight: 600;
        color: $font_blue;
        cursor: pointer;
        &__text {
            width: 60%;
        }
    }
}

.btn_box {
    width: 100%;
    padding: 4em 0 6em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
}

.confirm_section_box {
    width: 100%;
    margin: 1em 0;
}

.editable_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $font_blue;
    fill: $font_blue;
}

.section_title {
    font-size: 1.3em;
    font-weight: 500;
}

.section_icon {
    padding: 0 ;
    transform: scale(1.2);
    cursor: pointer;
}

.question_detail_container {
    width: 100%;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    margin-top: 0.3em;
    padding: 1em;
    padding-top: 0.5em;
}

.question_detail_box {
    margin-top: 0.5em;
    &__title {
        color: $font_light_blue;
    }
    &__content {
        margin-top: 0.3em;
        color: $font_black;
        white-space: pre-wrap;
        word-wrap: break-word; 
    }
}

.image_box {
    background: $bg-dashboard;
    border-radius: $borderRadius;
    margin-top: 0.3em;
    &__empty {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        color: $font_grey;
    }
}

.slide_container {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    &__img_box {
        position: relative;
        width: 12em;
        height: 12em;
        margin: 0.5em 0.3em;
        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid $border-dark-grey;
            border-radius: 5px;
        }
        &__delete {
            position: absolute;
            top: 4%;
            left: 4%;
            background: #F86454;
            color: $font_white;
            fill: $font_white;
            border-radius: 50%;
            width: 1.8em;
            height: 1.8em;
            padding: 0.2em;
            cursor: pointer;
        }
    }
}

.asking_price_box {
    width: 100%;
    background: $bg-dashboard;
    border-radius: $borderRadius;
    padding: 1em;
    display: flex;
    margin-top: 0.3em;
    margin-bottom: 0.5em;
    &__title {
        color: $font_light_blue;
        font-size: 1.3em;
        font-weight: 300;
    }
    &__price {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 600;
        margin-left: 1em;
    }
}