@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.auth_wrapper {
    display: flex;
    overflow: hidden;
}

.left_container {
    width: 50%;
    height: 100vh;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.right_container {
    position: relative;
    width: 50%;
    height: 100vh;

    .link_container1 {
        font-size: 1.1em;
    }

    .backward1 .aiforward1  {
        transform: scale(1.7);
    }

    @include breakpoint(md){
        .link_container1 {
            font-size: 0.9em;
        }
    }

    &__title {
        display: flex;
        width: 100%;
        margin-top: 3em;
        justify-content: space-between;

        .tfa_text_wrapper1 {
            width: 80%;
        }

        .primary_txt1 {
            font-size: 1.3em;
        }

        .secondary_txt1 {
            color: $font-blue;
            width: 100%;
            font-size: 2.2vw;
        }

        .description_txt1 {
            color: $font-black;
            width: 100%;
            font-weight: 600;
        }

        .user_icon {
            width: 4.3vw;
            height: 4.3vw;
            transform: rotate(13deg);
            fill: $user-icon;
        }

        @include breakpoint(xlg){
            .description_txt1 {
                font-size: 1.1vw;
            }
        }

        @include breakpoint(lg){
            .primary_txt1 {
                font-size: 1.1em;
            }
        }

        @include breakpoint(md){
            .primary_txt1 {
                font-size: 1.2em;
            }
            .secondary_txt1 {
                font-size: 2.8vw;
            }
            .description_txt1 {
                font-size: 1.3vw;
            }
        }

        &__icon {
            margin-top: 2vw;
            width: 4em;
            height: 4em;
        }
    }
}

.center_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
}

.auth_btn_wrapper {
    width: 100%;
    margin-top: 1em;
    display: block;

    .tfa_button_container1 {
        background: $btn-light;
        width: 100%;
        height: 2.5em;
        margin-top: 1em;
        &:hover {
            background: $btn-light;
        }
    }

    .tfa_button_container2 {
        background: $btn-dark;
        width: 100%;
        height: 2.5em;
        margin: 1.3em 0;
        &:hover {
            background: $btn-dark;
        }
    }

    @include breakpoint(md) {
        .tfa_button_container1, .tfa_button_container2 {
            font-size: 0.9em !important;
        }
    }
}