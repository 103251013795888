@import '../../assets/styles/GlobalStyle/global_variables.scss';

.link_container {
    color: $font_blue;
    font-weight: bold;
    height: 3.8em;
    width: 100%;
    font-size: 1.2vw;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
}

.forward {
    color: $font_white;
    padding-top: 0.2em;
    width: 2em;
    transform: scale(1.9);
    cursor: pointer;
}

.backward {
    color: $font_blue;
    padding-top: 0.2em;
    width: 2em;
    transform: scale(1.6);
    margin-right: 0.5em;
    cursor: pointer;
}