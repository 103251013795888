@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.skip_paypal_button {
    background-color: #3b7bbf;
    color: white;
    border-radius: 3px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 1.3em;
    font-style: italic;
    cursor: pointer;
}