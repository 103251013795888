@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.tfa_text_wrapper1 {
    width: 80%;
}

.primary_txt1 {
    color: $font_yellow;
    font-size: 1.5em;
}

.secondary_txt1 {
    width: 100%;
    color: $font_blue;
    font-size: 2.5em;
}

.description_txt1 {
    width: 90%;
    color: $font_black;
    font-size: 1.2em;
}

.avatar_box {
    position: relative;
    width: 100%;
    margin: 1em 0;
    &__avatar {
        margin: 0 auto;
        border: 4px solid $border-blue;
        background: $bg-blue !important;
    }
    &__camera {
        position: absolute !important;
        bottom: 0;
        right: 33%;
        &:hover {
            background: $user-icon !important;
        }
        &__avatar_input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.bio_box {
    width: 100%;
    padding-top: 1em;
    &__input_field {
        width: 100%;
    }
}

.title_box {
    margin: 1.8em 0 0.8em 0;
    width: 100%;
    &__primary {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        color: $font_black;
        font-weight: 500;
        margin: 0.3em 0;
        width: 75%;
        @include breakpoint(lg){
            width: 90%;
        }
    }
}

.section_title_box {
    width: 100%;
    margin: 1em;
    text-align: center;
    &__main {
        font-size: 1.7em;
        color: $font_blue;
        font-weight: 500;
        margin-bottom: 0.3em;
    }
    &__desc {
        font-size: 0.95em;
        color: $font_black;
        font-weight: 400;
    }
}

.unavailable_box {
    text-align: center;
    &__title_checkbox {
        color: $font_blue;
    }
}

.timezone_box {
    text-align: center;
}

.search_address_box {
    margin: 1em 0 1.5em 0;
}

.payment_wrapper {
    margin-top: 4em;
}

.adornment {
    font-family: $space_grotesk;
}

.bullet_points {
    list-style: none;
}

.bullet_points li::before {
    content: "\2022";
    color: $font_light_blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -2em;
}

.tnc_box {
    margin-top: 4em;
    border: 2px solid $border-blue;
    border-radius: 6px;
    padding-left: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tnc_icon {
    margin-right: 1em;
    cursor: pointer;
}

@include breakpoint(lg) {
    .tfa_text_wrapper1 {
        width: 100%;
    }
    .avatar_box {
        &__camera {
            right: 25%
        }
    }
}

.btn_box {
    width: 100%;
    padding: 4em 0 6em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
}