@import '../../../../assets/styles/GlobalStyle/global_variables.scss';


.link_container1 {
    font-size: 1.1em;
}

.backward1 .aiforward1  {
    transform: scale(1.7);
}

.right_container__title {
    display: flex;
    width: 100%;
    margin-top: 3em;
    justify-content: space-between;

    .tfa_text_wrapper1 {
        width: 70%;
    }
    .primary_txt1 {
        font-size: 1.3em;
    }

    .secondary_txt1 {
        color: $font-blue;
        width: 100%;
        font-size: 2.2vw;
    }

    .user_icon {
        width: 4.3vw;
        height: 4.3vw;
        color: $user-icon;
    }

    @include breakpoint(lg){
        .tfa_text_wrapper1 {
            width: 80%;
        }
        .secondary_txt1 {
            font-size: 1.8em;
        }
        .user_icon {
            width: 4vw;
            height: 4vw;
        }
    }

    @include breakpoint(md){
        .primary_txt1 {
            font-size: 1.2em;
        }
        .secondary_txt1 {
            font-size: 2.8vw;
        }
        .user_icon {
            width: 5vw;
            height: 5vw;
        }
    }

    @include breakpoint(sm){
        .primary_txt1 {
            font-size: 1em;
        }
        .secondary_txt1 {
            font-size: 3vw;
        }
    }

}

.center_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 26.5vw;
}

.input_wrapper {
    width: 100%;
}

.auth_btn_wrapper {
    margin: 1.3em 0;

    .tfa_button_container1 {
        background: $btn-light;
        height: 2.5em;

        &:hover {
            background: $btn-light;
        }
    }
}

.forgotPwd_text {
    width: 16em;
    font-size: 0.9em;
    margin: 2em 0;
    font-weight: 500;
}