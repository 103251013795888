@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.time_picker_box {
    width: 100%;
    display: flex;
    margin: 0.4em 0;
}

.title_checkbox {
    width: 11.3em;
}

.schedule_box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    &__start_box, &__end_box {
        display: flex;
        flex-direction: row !important;
    }
    &__start_box {
        margin-left: 2em;
    }
    &__end_box {
        margin-right: 2em;
    }
}

.time_form_control {
    width: 5.8em;
}

.form_control {
    width: 4.4em;
    margin: 0 0.3vw !important;
}

.divider {
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: $font_blue;
    font-size: 1.5em;
}

@include breakpoint(lg){
    .schedule_box {
        max-width: 300px;
        &__start_box {
            margin-left: 0em;
        }
        &__end_box {
            margin-right: 0em;
        }
    }
    .divider {
        margin: 0 0.3em;
    }
}