@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.professional_card_container, .professional_card_container_clicked {
    width: 100%;
    background: $bg-dashboard;
    border-radius: $borderRadius;
    padding: 1em 1.5em;
    cursor: pointer;
    &__empty_box{
        width: 100%;
        &__title {
            font-size: 1.1em;
            color: $font_blue;
            font-weight: 500;
        }
    }
}

.professional_card_container_clicked {
    -webkit-box-shadow: 0 0 0 2px $border-blue;
    -moz-box-shadow: 0 0 0 2px $border-blue;
    box-shadow: 0 0 0 2px $border-blue;
}

.professional_box {
    display: flex;
    align-items: center;
    &__icon {
        border: 1px solid $border-icon;
    }
    &__info {
        margin-left: 1em;
        &__top {
            display: flex;
            &__name {
                color: $font_blue;
                font-weight: 600;
            }
            &__devider {
                margin: 0 0.3em;
            }
            &__number {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $font_blue;
                font-weight: 300;
            }
        }
        &__bottom {
            display: flex;
            &__experience {
                color: $font_black;
                font-weight: 600;
            }
            &__category {
                margin-left: 0.5em;
                color: $font_black;
                font-weight: 300;
            }
        }
    }
}

.bio_box {
    margin-top: 0.4em;
    &__content {
        color: $font_black;
    }
}

.price_box {
    margin-top: 0.7em;
    display: flex;
    align-items: center;
    &__title {
        color: $font_light_blue;
        font-size: 1.3em;
        font-weight: 300;
    }
    &__price {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 600;
        margin-left: 1em;
    }
}