@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.address_complete_container {
    width: 100%;
}

.suggestions_box {
    width: 100%;
    border: 2px solid $border-blue;
    border-radius: 6px;
    padding: 0.5em;
    margin-top: 0.1em;
}

.suggestion_cell {
   cursor: pointer;
}

.result_container {
    position: relative;
}

.disabled_circular_progress {
    width: 100%;
    display: flex;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: $font_light_blue !important;
    padding: 0.3em;
}

.result_content_box {
    cursor: pointer;
}