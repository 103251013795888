@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    position: relative;
    transition: width 0.5s;
    width: 20%;
    height: 100vh;
    display: block;
    overflow: hidden;
}

.min_width {
    display: none;
}

.sidebar_box_width {
    min-width: 17em;
}

.logo_box {
    width: 100%;
    height: 4em;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid $border-grey;
}

.min_logo_box {
    width: 100%;
    height: 4em;
    border-bottom: 3px solid $border-grey;
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
    &__min_notification_log {
        width: 2.6em;
        height: 2.6em;
        color: $font_white;
        background: $user-icon;
        padding: 0.3em;
        border-radius: 10px;
        cursor: pointer;
    }
}

.logo {
    margin-left: 1em;
    width: 6em;
    height: 4em;
}

.notification_badge {
    margin: auto 1em;
}

.notification_log,
.notification_badge_box {
    color: $font_white;
    width: 3em;
    height: 3em;
    background: $user-icon;
    border-radius: 10px;
    padding: 0.5em;
    cursor: pointer;
}

.notification_log {
    margin: auto 1em;
}

.user_box {
    display: flex;
    width: 100%;
    height: 5em;
}

.min_user_box {
    width: 100%;
    height: 5em;
    display: grid;
    align-items: center;
    justify-content: center;
    &__min_avator {
        border: 1px solid $border-icon;
    }
}

.avator {
    border: 1px solid $border-icon;
    margin: auto 1em;
}

.user_name_box {
    margin: auto 0;
    &__avatar_name {
        color: $font_blue;
        font-weight: 600;
    }
    &__avatar_number {
        color: $font_blue;
        font-weight: 300;
    }
    &__avatar_title {
        font-weight: 300;
    }
}

.menu_tab_box {
    width: 100%;
    height: 100%;
}

@include breakpoint(lg) {
    .wrapper {
        transition: width 0.5s;
        width: 3.8em;
    }

    .max_width {
        display: none;
    }

    .min_width{
        display: block;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        &:last-child {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50% ,-50%);
        }
    }
}