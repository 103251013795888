@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow-y: auto;

    .logo {
        position: absolute;
        top: 2.5%;
        left: 1%;
    }

    .main_container {
        margin: 2em auto;
        width: 45em;
        height: 100%;
        @include breakpoint(lg) {
            width: 35em;
        }
    }
}

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.progress_box {
    width: 100%;
    &__title {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 500;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
    &__state {
        width: 100%;
    }
    .state_container {
        justify-content: space-between;
    }
    .state_icon {
        text-align: center;
        width: 18%;
        padding: 0.4em;
    }
}