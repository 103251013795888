@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.stripe_container {
    width: 100%;
}

.bank_account_box {
    border: 2px solid $border-blue;
    border-radius: $btn-border-radius;
    margin-top: 0.5em;
    &__account_number, 
    &__account_number_dashboard,
    &__bsb,
    &__bsb_dashboard {
        border-radius: $btn-border-radius;
        font-family: $space_grotesk;
        padding: 0.8em;
        border: none;
        font-weight: 400;
        font-size: 1em;
        &::-webkit-input-placeholder {
            color: $font_blue;
            font-weight: 700;
            font-size: 1.1em;
        }
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        &:focus {
            outline: none;
        }
    }
    &__account_number,
    &__account_number_dashboard {
        width: 65%;
    }
    &__bsb,
    &__bsb_dashboard {
        width: 35%;
    }
    &__bsb_dashboard,
    &__account_number_dashboard {
        background: $bg-dashboard;
    }
}