@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.profile_title {
    font-size: 1.2em;
    color: $font_blue;
    font-weight: 500;
}

.container {
    width: 98%;
    height: 5em;
    border-radius: $borderRadius;
    background: $bg-white;
    display: flex;
    margin-top: 0.5em;
    &__avatar {
        margin: auto 0.7em;
        border: 1px solid $border-icon;
    }
    &__profile_info {
        margin: auto 0;
        &__name {
            color: $font_blue;
            font-weight: 500;
            &__code {
                font-weight: 300;
            }
        }
        &__detail {
            color: $font_black;
        }
    }
}

@include breakpoint(md) {
    .container {
        height: 4em;
    }
}