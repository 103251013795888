@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    .logo {
        position: absolute;
        top: 2.5%;
        left: 1%;
    }
    .main_container {
        margin: 2em auto;
        width: 40em;
        height: 100%;
        
        @include breakpoint(lg) {
            width: 28em;
        }
    }
}

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.main_content_container {
    width: 100%;
    .tfa_text_wrapper1{
        margin-top: 2em;
    }
    .primary_txt1 {
        font-size: 1.5em;
    }
    .secondary_txt1 {
        width: 80%;
        font-size: 2.5em;
        color: $font_blue;
    }
    .tfa_sub_text_primary {
        margin-top: 2em;
    }
}

.title_box {
    margin: 1.8em 0 0.8em 0;
    width: 100%;
    &__primary {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        font-weight: 400;
        margin: 0.3em 0;
        width: 75%;
        @include breakpoint(lg) {
            width: 90%;
        }
        &__bold {
            font-weight: 700;
        }
    }
    &__simple_input {
        width: 100%;
        padding-top: 1em;
    }
}

.confirm_section_box {
    width: 100%;
    margin: 1em 0;
}

.editable_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $font_blue;
    fill: $font_blue;
}

.section_title, .remove_title {
    font-size: 1.3em;
    font-weight: 500;
}

.remove_title {
    color: $font_blue;
}

.section_icon {
    position: relative;
    padding: 0 ;
    transform: scale(1.2);
    overflow: hidden;
    z-index: 1;
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
        z-index: 2;
        &::-webkit-file-upload-button { 
            cursor:pointer; 
        }
    }
}

.question_detail_container {
    width: 100%;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    margin-top: 0.3em;
    padding: 1em;
    padding-top: 0.5em;
}

.question_detail_box {
    margin-top: 0.5em;
    &__title {
        color: $font_light_blue;
    }
    &__content {
        margin-top: 0.3em;
        color: $font_black;
    }
}

.image_box {
    background: $bg-dashboard;
    border-radius: $borderRadius;
    margin-top: 0.3em;
    &__empty {
        width: 100%;
        height: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1em;
        color: $font_grey;
    }
}

.slide_container {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    &__uploading_box {
        position: relative;
        width: 12em;
        height: 12em;
        margin: 0.5em;
        background: rgba(199, 199, 199, 0.5);
        border-radius: 5px;
        &__circular, &__progress {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $font_blue !important;
        }
        &__circular {
            width: 100% !important;
            height: 100% !important;
            padding: 4.2em;
        }
    }
    &__img_box {
        position: relative;
        width: 12em;
        height: 12em;
        margin: 0.5em 0.3em;
        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid $border-dark-grey;
            border-radius: 5px;
        }
        &__delete {
            position: absolute;
            top: 4%;
            left: 4%;
            background: #F86454;
            color: $font_white;
            fill: $font_white;
            border-radius: 50%;
            width: 1.8em;
            height: 1.8em;
            padding: 0.2em;
            cursor: pointer;
        }
    }
}

.remove_container {
    background: $bg-dashboard;
    border-radius: $borderRadius;
    padding: 1em;
    margin-top: 0.5em;
    .tfa_button_container1 {
        height: 2.5em;
        width: 13em;
        background: $btn-delete;
        margin-top: 1em;
        &:hover {
            background: $btn-delete;
        }
    }
}

.file_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    margin: 0.3em 0;
    &__title_box {
        display: flex;
        width: 100%;
        &__icon {
            margin: auto 1em;
        }
        &__text_box {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 100%;
            max-width: 20em;
            margin: 1em 0;
            &__top {
                color: $font_light_blue;
                font-size: 1.2em;
                font-weight: 400;
            }
            &__bottom {
                height: 100%;
                color: $font_black;
                font-size: 1em;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
    &__delete {
        margin: auto 0;
        margin-right: 1em;
        transform: scale(1.2);
        fill: #F86454;
        cursor: pointer;
    }
}

.btn_box {
    width: 100%;
    padding: 4em 0 6em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
}
