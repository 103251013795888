@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.container {
    margin: 1em 0;
    &__file_box {
        width: 98%;
        display: flex;
        justify-content: space-between;
        border-radius: $borderRadius;
        background: $bg-white;
        margin: 0.3em 0;
        &__title_box {
            display: flex;
            &__icon {
                margin: auto 1em;
            }
            &__info_box {
                height: 100%;
                margin: 1em 0;
                &__title {
                    max-width: 17em;
                    color: $font_light_blue;
                    font-size: 1.2em;
                    font-weight: 400;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                    @include breakpoint(md){
                        max-width: 10em;
                    }
                }
                &__type {
                    color: $font_black;
                    font-size: 1em;
                    font-weight: 400;
                    text-transform: uppercase;
                }
            }
        }
        &__install {
            margin: auto 1em;
            padding: 0.1em;
            transform: scale(1.7);
            color: $btn-dark;
            cursor: pointer;
            &:hover {
                border-radius: 50%;
                background-color: $border-grey;
            }
        }
    }
}