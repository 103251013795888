@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.state_box {
    display: flex;
}

.state {
    padding: 0.3em 0.7em;
    margin: 0 0.5em;
    font-size: 1em;
    border-radius: 5px;
    font-weight: 500;
}

.single_state {
    padding: 0.2em 0.7em;
    font-size: 1em;
    border-radius: 5px;
    font-weight: 500;
}

.completed_state {
    color: $font-completed;
    background: $bg-completed;
}

.active_state {
    color: $font-active;
    background: $bg-active;
}

.uncompleted_state {
    color: $font-uncompleted;
    background: $bg-uncompleted;
    border: 1px solid $font-uncompleted;
}

.cancelled_state {
    color: $font-cancelled;
    background: $bg-cancelled;
}

@include breakpoint(lg){
    .state {
        font-size: 1.3vw;
    }
    .single_state {
        font-size: 1.2vw;
    }
}

@include breakpoint(md){
    .state {
        font-size: 1.3vw;
    }
}

@include breakpoint(sm){
    .state {
        font-size: 1.3vw;
    }
}
