@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_content_box {
    width: 80%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: $bg-dashboard;
}

.template_container {
    width: 30%;
    padding-left: 1em; 
}

.edit_container {
    width: 70%;
    height: 100vh;
    padding-left: 1em;
}

.template_list {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    &__box {
        display: flex;
        width: 95%;
        margin: 0.7em 0;
        margin-left: 0.1em;
        padding: 1em 0.5em;
        border-radius: $borderRadius;
        background: $bg-white;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
            -webkit-box-shadow: 0 0 0 2px $border-blue;
            -moz-box-shadow: 0 0 0 2px $border-blue;
            box-shadow: 0 0 0 2px $border-blue;
        }
        &__title {
            padding-left: 0.3em;
            color: $font_blue;
            font-size: 1.1em;
            font-weight: 700;
            max-width: 95%;
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis;
        }
        &__arrow {
            margin: auto 0;
            color: $font_light_blue;
            transform: scale(1.1);
            max-width: 5%;
        }
    }
    &__selected {
        -webkit-box-shadow: 0 0 0 2px $border-blue;
        -moz-box-shadow: 0 0 0 2px $border-blue;
        box-shadow: 0 0 0 2px $border-blue;
    }
}

.empty_template_box {
    width: 98%;
    height: 3.5em;
    border-radius: $borderRadius;
    background: $bg-white;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.new_template_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tfa_button_container1 {
        width: 50%;
        font-size: 0.9em;
        margin-top: 1em;
        background-color: $btn-light;
        &:hover {
            background-color: $btn-light
        }
    }   
}

.edit_box {
    position: relative;
    width: 98%;
    height: 100vh;
    border-radius: $borderRadius;
    background: $bg-white;
}

.empty_box_title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $font_grey;
}

.template_box {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 3em;
    &__title_box {
        display: flex;
        padding: 1em;
        align-items: center;
        &__label {
            font-size: 1.2em;
            font-weight: 500;
            color: $font_light_blue;
        }
        &__title {
            width: 100%;
            margin: 0 0.5em;
            font-size: 1.3em;
            font-weight: 700;
            color: $font_blue;
            font-family: $space_grotesk;
            border: none;
            &:focus {
                outline: none;
            }
        }
    }
    &__hr_divider {
        width: 100%;
        height: 0.3em;
        background: $bg-dashboard;
    }
    &__body_box {
        position: relative;
        width: 100%;
        text-align: end;
        &__body {
            width: 100%;
            padding: 1em;
            border: none;
            font-weight: 500;
            font-size: 1em;
            font-family: $space_grotesk;
            &:focus {
                outline: none;
            }
        }
        .tfa_button_container2 {
            margin: 1em;
            height: 2.7em;
            width: 7em;
            color: $font_white;
            background: $btn-green;
            &:hover {
                background: $btn-green;
            }
        }
        .tfa_button_container3 {
            margin: 1em;
            height: 2.7em;
            width: 7em;
            color: $font_white;
            background: $btn-delete;
            &:hover {
                background: $btn-delete;
            }
        }
    }
}

@include breakpoint(lg){
    .main_content_box {
        width: 100%;    
    }
    .template_container {
        width: 35%;
    }
    .template_list {
        &__box {
            &__title {
                font-size: 1em;
            }
        }
    }
}

@include breakpoint(md){
    .template_container {
        width: 35%;
    }
    .template_list {
        &__box {
            &__title {
                font-size: 0.9em;
            }
        }
    }
}