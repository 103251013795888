@import '../../../assets/styles/GlobalStyle/global_variables.scss';


.container {
    width: 98%;
    border-radius: $borderRadius;
    background: $bg-white;
    margin: 0.8em 0;
    padding: 1em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: auto;
    &__image_box {
        position: relative;
        &__image {
            object-fit: cover;
            width: 8em;
            height: 8em;
            border: 1px solid $border-dark-grey;
            border-radius: 5px;
            margin: 0.3em;
        }
        &__icon {
            position: absolute;
            top: 8%;
            right: 10%;
            background: $btn-light;
            fill: $font_white;
            border-radius: 50%;
            width: 1.5em;
            height: 1.5em;
            padding: 0.3em;
            cursor: pointer;
        }
    }
}