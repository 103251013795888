@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    position: relative;
    height: 100vh;
    width: 100%;

    .logo {
        position: absolute;
        top: 2%;
        left: 2%;
    }

    &__main_container {
        margin: 2em auto;
        width: 28em;
        height: 100%;

        .link_container1 {
            font-size: 1.2em;
        }

        .tfa_text_wrapper1 {
            margin-bottom: 0.5em;
        }

        .secondary_txt1 {
            width: 100%;
            font-size: 2.4em;
            margin-top: 0.7em;
            color: $font-blue;
        }

        .description_txt1 {
            width: 100%;
            font-size: 1em;
            color: $font-black;
        }

        .tfa_button_container1 {
            height: 3em;
            margin: 2.5em 0;
            background-color: $btn-light;
            &:hover {
                background-color: $btn-light;
            }
        }
        .tfa_sub_text_wrapper {
            margin-top: 2em !important;
        }

        .tfa_sub_text_description {
            width: 100% !important;
        }
    }
}

.mara_title_vontainer {
    margin-bottom: 0.3em;
}

.file_box {

    &__file_input::-webkit-file-upload-button {
        visibility: hidden;
    }
    
    &__file_input::before {
        content: 'Select some files';
        display: inline-block;
        background: linear-gradient(top, #f9f9f9, #e3e3e3);
        border: 1px solid #999;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 10px;
    }
    
    &__file_input:hover::before {
        border-color: black;
    }
    
    &__file_input:active:before {
        background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
}

.modal_icon {
    width: 100%;
    height: 5.5em;
    margin: 2.5em 0 1em 0;
    color: $btn-light;
}

.modal_text_box {
    .secondary_txt1 {
        width: 100%;
        color: $font-blue;
        padding: 0 1em;
        font-size: 2.1em;
    }
    
    .description_txt1 {
        width: 100%;
        color: $font_black;
        padding: 0 2.9em;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.confirm_btn_box {
    .tfa_button_container1 {
        width: 90%;
        background: $btn-light;
        height: 3em;
        font-size: 1em !important;
        margin: 1em 0 !important;
        &:hover {
            background: $btn-light;
        }
    }
}