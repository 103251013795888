@import '../../assets/styles/GlobalStyle/global_variables.scss';

.tfa_text_wrapper {
    display: block;
}

.primary_txt {
    color: $font_yellow;
    font-weight: bold;
    width: 100%;
    font-size: 1.5vw;
    margin: 0;
}

.secondary_txt {
    color: #FFFFFF;
    font-weight: bold;
    width: 48vw;
    font-size: 2.7em;
    margin: 0.25em 0;
}

.description_txt {
    color: #FFFFFF;
    width: 28vw;
    font-size: 1vw;
    margin: 0;
}
