@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.answer_input_box {
    border-radius: $borderRadius;
    background: $bg-white;
    margin-bottom: 0.5em;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    overflow-y: auto;
    overflow-x: hidden;
}

.answer_input_field {
    margin: 1vw;
    margin-right: 0;
    width: 87%;
}

.uploadIcons {
    fill: #2F68FA;
}

.upload_file_box {
    position: relative;
    z-index: 0;
    margin-left: 0.3em !important;
    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
    }
    &__input[type=file]::-webkit-file-upload-button {
        cursor: pointer;
    }
}

.submitBtn_box {
    width: 13%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &__icon{
        margin-bottom: 1.5vw;
        padding: 0.3em;
        background: $user-icon;
        border-radius: $btn-border-radius;
        transform: scale(1.9);
        cursor: pointer;
    }
}

@include breakpoint(lg){
    .submitBtn_box{
        &__icon{
            margin-bottom: 1.7vw;
            transform: scale(1.8);
        }
    }
}

@include breakpoint(md){
    .answer_input_field{
        width: 83%;
    }
    .submitBtn_box{
        width: 17%;
        &__icon{
            transform: scale(1.5);
        }
    }
}

@include breakpoint(sm){
    .answer_input_field{
        width: 80%;
    }
    .submitBtn_box{
        width: 20%;
        &__icon{
            transform: scale(1.4);
        }
    }
}