@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    position: relative;
    width: 100%;
    margin-top: 2em;
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.hidden_code_input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
}

.digit_container, .digit_container_highlighted {
    position: relative;
    margin: 0 0.5em;
    width: 4em;
    height: 4em;
    border-radius: $btn-border-radius;
    text-align: center;
    align-items: center;
}

.digit_container {
    border: 2px solid $border-blue;
}

.digit_container_highlighted {
    border: 3px solid $border-blue;
}

.digit_input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $btn-dark;
    font-size: 1.5em;
}