@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.log_wrapper {
    width: 100%;
    border-radius: $borderRadius;
    background: $bg-white;
    margin-bottom: 0.5em;
    padding: 0 0.7em;

    &__log_name {
        display: flex;
        margin: 0.7em 0 0.4em 0;
        &__user {
            color: $font-blue;
            font-weight: 600;
            margin-right: 1vw;
        }
        &__time {
            color: $font_grey;
            font-weight: 500;
        }
    }
    &__with_file, &__without_file {
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
    }
    &__with_file {
        border-bottom: 2px solid rgba(199, 199, 199, 0.4);
    }
    &__log_file {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 0.5em;
        &__file {
            position: relative;
            &__image {
                object-fit: cover;
                width: 6em;
                height: 6em;
                border: 1px solid $border-dark-grey;
                border-radius: 5px;
                margin: 0.3em 0.5em;
            }
            &__install {
                position: absolute;
                top: 8%;
                right: 10%;
                background: $btn-light;
                fill: $font_white;
                border-radius: 50%;
                width: 1.5em;
                height: 1.5em;
                padding: 0.3em;
                cursor: pointer;
            }
        }
    }
    &__log_status {
        margin-bottom: 1em;
    }
}

.log_state_box {
    width: 100%;
    margin: 1em 0;
    &__title {
        font-size: 1.1em;
        color: $font_blue;
        font-weight: 500;
    }
    &__body {
        font-size: 0.9em;
        color: $font_black;
    }
    &__option_box {
        display: flex;
    }
}

.state_green_btn,
.state_yellow_btn {
    margin-top: 0.5em;
    margin-right: 0.5em;
    padding: 0.4em 0.7em;
    border-radius: 5px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2);
    }
}

.state_green,
.state_yellow,
.state_red {
    margin-top: 0.5em;
    margin-right: 0.5em;
    padding: 0.4em 0.7em;
    border-radius: 5px;
}

.state_green_btn, .state_green {
    color: $font-completed;
    background: $bg-completed;
}

.state_yellow_btn, .state_yellow {
    color: $font-active;
    background: $bg-active;
}

.state_red {
    color: $font-cancelled;
    background: $bg-cancelled;
}

.content_text_style {
    white-space: pre-wrap;
    word-wrap: break-word; 
}