@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.question_title {
    font-size: 1.2em;
    color: $font_blue;
    font-weight: 500;
}

.container {
    width: 98%;
    border-radius: $borderRadius;
    background: $bg-white;
    margin: 0.5em 0;
    padding: 1em;
    &__title {
        color: $font_blue;
        font-size: 1.4em;
        font-weight: 500;
    }
    &__content {
        color: $font_black;
        white-space: pre-wrap;
        word-wrap: break-word;
    }
    &__expiry_box {
        margin: 0.3em 0;
        &__expiry {
            color: $font_grey;
            font-weight: 300;
        }
    }
}

@include breakpoint(lg) {
    .container {
        &__content {
            font-size: 0.9em;
        }
    }
}

@include breakpoint(md) {
    .container {
        &__title {
            font-size: 2.3vw;
        }
        &__content {
            font-size: 0.8em;
        }
    }
}