@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.container {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
}

.input_container {
    width: 100% !important;
    height: 3em !important;
    margin-left: 15% !important;
    padding-left: 1em !important;
    border: 2px solid $border-blue !important;
    font-family: $space_grotesk;
    font-weight: 500;
    font-size: 1.2em !important;
}

.btn_container,
.bg_btn_container {
    width: 13% !important;
    border: 2px solid $border-blue !important;
    border-radius: $btn-border-radius !important;
}

.btn_container {
    background: $bg-white !important;
}

.bg_btn_container {
    background: $bg-dashboard !important;
}

.btn_container div {
    padding: 0 !important;
    margin: 0 auto;
}

.dropdown_container {
    border: 2px solid $border-blue !important;
    border-radius: $btn-border-radius !important;
}

.search_container {
    width: 100% !important;
}

.search_container span {
    display: none;
}