@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.section_container {
    height: 100%;
    width: 40%;
    padding-top: 1.5em;
    padding-left: 2.5em;
    overflow-x: hidden;
    overflow-y: auto;
}

.section_box,
.second_section_box {
    width: 100%;
    &__title {
        color: $font_blue;
        font-size: 1.2em;
        font-weight: 500;
    }
    &__desc {
        color: $font_black;
        margin-bottom: 0.5em;
    }
}

.second_section_box {
    margin-top: 1.5em;
    &__btn {
        width: 100%;
        margin-top: 1em;
        display: block;
        .tfa_button_container1 {
            width: 100%;
            height: 2.5em;
            background: $btn-light;
            margin-top: 0.5em;
            &:hover {
                background: $btn-light;
            }
        }
        .tfa_button_container2 {
            width: 100%;
            height: 2.5em;
            background: $btn-white;
            color: $btn-light;
            border: 2.5px solid $btn-light;
            margin-top: 0.5em;
            &:hover {
                background: $btn-white;
            }
        }
    }
}

.payment_method_box {
    width: 100%;
    max-height: 35em;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0.5em;
    &__card {
        margin-bottom: 1em;
    }
}

.transaction_box {
    height: 90vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 0.5em;
    &__history_card {
        width: 98%;
        background: $bg-white;
        border-radius: $borderRadius;
        padding: 0.8em 1em;
        margin: 0.5em 0;
    }
}

.history_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__price_box {
        display: flex;
        &__title {
            font-size: 1.2em;
            color: $font_light_blue;
        }
        &__price {
            font-size: 1.3em;
            color: $font_blue;
            margin-left: 0.8em;
            font-weight: 600;
        }
    }
    &__category {
        font-weight: 500;
    }
}

.history_title {
    width: 98%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 600;
    margin: 0.5em 0;
}

.history_state_box {
    display: flex;
    &__detail {
        margin-left: 0.8em;
    }
}

.select_box {
    margin-top: 1em;
}

.avatar_box {
    position: relative;
    width: 100%;
    margin: 1em 0;
    &__avatar {
        margin: 0 auto;
        border: 4px solid $border-blue;
        background-color: $bg-blue !important;
    }
    &__camera {
        position: absolute !important;
        bottom: 0;
        right: 10vw;
        &:hover{
            background: $user-icon !important;
        }
        &__avatar_input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.btn_box {
    width: 100%;
    padding: 3em 0 2em 0;
    margin: 0 auto;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
    .tfa_button_container2 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
    .tfa_button_container3 {
        color: $btn-light;
        background: $btn-white;
        border: 2.5px solid $btn-light;
        height: 2.5em;
        margin: 1em 0;

        &:hover {
            background: $btn-white;
        }
    }
}

.wrapper {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    .logo {
        position: absolute;
        top: 2.5%;
        left: 1%;
    }
    .main_container {
        margin: 2em auto;
        width: 40em;
        height: 100%;
        
        @include breakpoint(lg) {
            width: 28em;
        }
    }
}

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.main_content_container {
    width: 100%;
    .tfa_text_wrapper1{
        margin-top: 2em;
    }
    .primary_txt1 {
        font-size: 1.5em;
    }
    .secondary_txt1 {
        width: 100%;
        font-size: 2.5em;
        color: $font_blue;
    }
}

@include breakpoint(xlg) {
    .avatar_box {
        &__camera {
            right: 8vw;
        }
    }
}
@include breakpoint(lg) {
    .avatar_box {
        &__camera {
            right: 9vw;
        }
    }
}
@include breakpoint(md) {
    .avatar_box {
        &__camera {
            right: 7vw;
        }
    }
    .history_top {
        &__category {
            font-size: 0.8em;
        }
    }
}
@include breakpoint(sm) {
    .avatar_box {
        &__camera {
            right: 3vw;
        }
    }
    .history_top {
        &__category {
            display: none;
        }
    }
    .history_state_box {
        flex-direction: column;
        &__detail {
            margin-left: 0;
        }
    }
}