@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_content_box {
    width: 80%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: $bg-dashboard;
}

.account_container {
    width: 40%;
    padding-top: 1.5em;
    padding-left: 1.5em;
}

.client_box {
    display: flex;
    background: $bg-white;
    border-radius: $borderRadius;
    padding: 1.5em 0.3em;
    overflow: hidden;
    &__detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &__name {
            color: $font_blue;
            font-size: 1.3em;
            font-weight: 600;
        }
        &__email {
            color: $font_light_blue;
            font-size: 1.1em;
        }
    }
}

.setting_box {
    height: 100%;
    margin-top: 1em;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 0.2em;
    padding-bottom: 13em;
    &__title {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 400;
        margin-bottom: 0.5em;
    }
    &__card,
    &__card_selected {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0.8em 1em;
        background: $bg-white;
        border-radius: $borderRadius;
        margin: 0.5em 0;
        transition: box-shadow 0.5s;
        cursor: pointer;
        &__title {
            color: $font_blue;
            font-weight: 600;
            font-size: 1.3em;
        }
        &__arrow {
            margin: auto 0;
            transform: scale(1.3);
            color: $font-light-blue;
            display: flex;
            align-items: center;
        }
        &:hover {
            -webkit-box-shadow: 0 0 0 2px $border-blue;
            -moz-box-shadow: 0 0 0 2px $border-blue;
            box-shadow: 0 0 0 2px $border-blue;
        }
    }
    &__card_selected {
        -webkit-box-shadow: 0 0 0 2px $border-blue;
        -moz-box-shadow: 0 0 0 2px $border-blue;
        box-shadow: 0 0 0 2px $border-blue;
    }
}

.avatar {
    border: 3px solid $border-blue;
    margin: auto 1em;
}

@include breakpoint(lg) {
    .main_content_box {
        width: 100%;
    }
}

@include breakpoint(md) {
    .client_box {
        padding: 1em 0;
        &__detail {
            &__name {
                font-size: 1em;
            }
            &__email {
                font-size: 1em;
            }
        }
    }
    .avatar {
        width: 2.5em !important;
        height: 2.5em !important;
    }
    .setting_box {
        &__card {
            &__title {
                font-size: 1em;
            }
        }
    }
}

@include breakpoint(sm) {
    .client_box {
        &__detail {
            &__name {
                font-size: 0.7em;
            }
            &__email {
                font-size: 0.7em;
            }
        }
    }
    .avatar {
        width: 2em !important;
        height: 2em !important;
    }
    .setting_box {
        &__card {
            &__title {
                font-size: 1em;
            }
        }
    }
}