@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.job_search_box {
    width: 80%;
    height: 100vh;
    overflow: hidden;
    background: $bg-dashboard;
}

.searchbar_box {
    margin: 1em;
}

.job_scroll_table {
    width: 100%;
    height: 100%;
}

.card_scroll_table {
    width: 100%;
    height: 100%;
    padding-bottom: 7em;
    overflow-y: scroll;
    overflow-x: hidden;
}

.job_cards_box {
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.skeleton_container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.empty_job_search_box {
    width: 100%;
    height: 85vh;
    margin: auto 0;
    background: $bg-white;
    border-radius: $borderRadius;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
}

.skeleton_detail_container {
    width: 50%;
    height: 100vh;
    padding: 0 0.5em;
}

.job_selected_box {
    width: 98%;
    margin: 0 auto;
    display: flex;
    &__job_list {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-bottom: 7em;
    }
    &__detail_box {
        width: 50%;
        height: 100vh;
    }
}

.job_detail {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 7em;
    border-radius: $borderRadius;
    background: $bg-white;
    &__back {
        width: 10em;
        display: flex;
        padding: 1em;
        cursor: pointer;
        &__arrow {
            transform: scale(1.1);
            fill: $close-icon;
        }
        &__title {
            font-size: 1.2em;
            color: $font_blue;
            font-weight: 500;
            margin-left: 1em;
        }
    }
    &__client {
        display: flex;
        width: 98%;
        height: 4em;
        border-radius: 10px;
        background: $bg-white;
        &__avator {
            margin: auto 0.7em;
            border: 1px solid $border-icon;
        }
        &__text {
            margin: auto 0;
            &__name {
                color: $font_blue;
                font-weight: 500;
            }
            &__question_count {
                color: $font_black;
            }
        }
    }
    &__question_box {
        width: 98%;
        border-radius: 10px;
        background: $bg-white;
        padding: 1em;
        &__title {
            color: $font-blue;
            font-size: 1.4em;
            font-weight: 500;
        }
        &__content {
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        &__expiry_box {
            margin: 0.4em 0;
            &__txt {
                color: $font_grey;
                font-weight: 300;
            }
        }
    }
    &__image_box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 98%;
        border-radius: $borderRadius;
        background: $bg-white;
        &__container {
            position: relative;
            &__img {
                object-fit: cover;
                width: 8em;
                height: 8em;
                border: 1px solid $border-dark-grey;
                border-radius: 5px;
                margin: 0.3em 0.5em;
            }
            &__install_icon {
                position: absolute;
                top: 8%;
                right: 10%;
                background: $btn-light;
                fill: $font_white;
                border-radius: 50%;
                width: 1.5em;
                height: 1.5em;
                padding: 0.2em;
                cursor: pointer;
            }
        }
    }
    &__document_box {
        margin: 1em 0;
        &__container {
            width: 98%;
            display: flex;
            justify-content: space-between;
            border-radius: $borderRadius;
            background: $bg-dashboard;
            margin: 0.3em auto;
            &__title_box {
                display: flex;
                &__icon {
                    margin: auto 0.5em;
                }
                &__text_box {
                    height: 100%;
                    margin: 1em 0;
                    &__top{
                        max-width: 15em;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis; 
                        display: block;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                        color: $font_light_blue;
                        font-size: 1.2em;
                        font-weight: 400;
                    }
                    &__bottom {
                        color: $font_black;
                        font-size: 1em;
                        font-weight: 400;
                    }
                }
            }
            &__install {
                margin: auto 1em;
                padding: 0.1em;
                transform: scale(1.7);
                color: $btn-dark;
                cursor: pointer;
                &:hover {
                    border-radius: 50%;
                    background-color: $border-grey;
                }
            }
        }
    }
    &__submit_box {
        width: 98%;
        display: flex;
        &__price {
            margin-top: 1em;
            margin-left: 1vw;
            padding-right: 1em;
            &__price_title {
                font-size: 1.6em;
                color: $font-light-blue;
                margin-bottom: 0.2em;
            }
            &__price_txt {
                margin-left: 0.5em;
                font-weight: 600;
                color: $font_blue;
            }
            &__price_desc {
                font-size: 0.9em;
            }
            @include breakpoint(md){
                &__price_title, &__price_txt {
                    font-size: 2.1vw;
                }
                &__price_desc {
                    font-size: 1.6vw;
                }
            }
            @include breakpoint(sm){
                &__price_title, &__price_txt {
                    font-size: 1.15em;
                }
            }
        }
        &__submit {
            text-align: center;
            margin-top: 1em;
            .tfa_button_container1 {
                background: $btn-light;
                height: 3.5em;
                width: 13em;
                font-size: 1em;
                &:hover {
                    background: $btn-light;
                }
                @include breakpoint(md){
                    font-size: 0.8em;
                    width: 17vw;
                }
                @include breakpoint(sm){
                    font-size: 0.7em;
                    width: 16vw;
                }
            }
            &__offer_btn {
                width: 100%;
                cursor: pointer;
                &__txt {
                    padding: 1.5em 0;
                    color: $font-light-blue;
                    @include breakpoint(md){
                        font-size: 0.98em;
                        padding: 1.5vw 0;
                    }
                }
            }

        }
    }
}

.modal_icon {
    width: 100%;
    height: 6em;
    margin: 2.5em 0 1em 0;
    fill: $user-icon;
}

.modal_text_box {
    .secondary_txt1 {
        width: 100%;
        color: $font-blue;
        padding: 0 1em;
        font-size: 2.1em;
    }
    
    .description_txt1 {
        width: 100%;
        color: $font_black;
        padding: 0 2.9em;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.price_container {
    margin: 1em 0;
}

.price_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 4em;
    &__receive_text {
        font-size: 1.3em;
        color: $font-light-blue;
        font-weight: 600;
    }
    &__asking_text {
        font-size: 1.1em;
        color: rgba(47, 104, 250, 0.4);
        font-weight: 400;
    }
    &__receive_price {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 600;
    }
    &__asking_price {
        font-size: 1.1em;
        color: rgba(2, 40, 115, 0.4);
        font-weight: 400;
    }
}

.fee_text {
    width: 100%;
    padding: 0 4em;
    text-align: end;
    font-size: 0.9em;
    color: rgba(2, 40, 115, 0.4);
}

.confirm_btn_box {
    margin-bottom: 1em;
    .tfa_button_container1 {
        width: 90%;
    }
    .tfa_button_container2 {
        width: 11em;
    }
    .tfa_button_container1, .tfa_button_container2 {
        background: $btn-light;
        height: 3em;
        font-size: 1em !important;
        margin: 1em 0 !important;
        &:hover {
            background: $btn-light;
        }
    }
}

@include breakpoint(lg) {
    .job_search_box {
        width: 100%;    
    }
}