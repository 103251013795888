@import '../../assets/styles/GlobalStyle/global_variables.scss';

.container {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    z-index: 100;
}

@include breakpoint(xsm) {
    .container {
        display: flex;
        justify-content: center;
    }
}

.alert_container {
    width: 20em;
    height: 14em;
    margin-top: 10em;
    border-radius: $borderRadius;
    background: #F0F0F0;
}

.center_content {
    display: flex;
    flex-direction: column;
    padding: 1em;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.zoom_out_icon {
    transform: scale(1.6);
    margin: 0.5em 0;
    color: $close-icon;
}

.zoom_out_title {
    color: $font_blue;
    font-weight: bold;
    font-size: 1.3em;
    margin-bottom: 0.5em 0;
}

.zoom_out_desc {
    margin: 0.5em;
    font-size: 0.7em;
}