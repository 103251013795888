@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_content_box {
    width: 80%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: $bg-dashboard;
}

.kits_container {
    width: 40%;
    padding-top: 1.5em;
    padding-left: 1.5em;
}

.kit_list_container {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 7em;
}

.purchased_box,
.available_box {
    width: 100%;
}

.empty_kit_box {
    width: 98%;
    height: 3em;
    border-radius: $borderRadius;
    background: $bg-white;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    padding: 1em;
    justify-content: flex-start;
    align-items: center;
    &__icon {
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.4em;
        color: $font_blue;
        font-weight: 700;
        margin-left: 1em;
    }
}

.about_kit_box,
.include_kit_box {
    width: 100%;
    text-align: start;
    padding: 0 1em;
    &__title {
        color: $font_blue;
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 0.3em;
    }
    &__desc {
        color: $font_black;
        font-size: 0.9em;
    }
    &__list_box {
        width: 100%;
        list-style: none;
        padding: 0 1em;
        &__list {
            margin-bottom: 0.5em;
            font-size: 0.9em;
        }
        &__list::before {
            content: "\2022";
            color: $font_light_blue;
            font-weight: bold;
            display: inline-block; 
            width: 1em;
            margin-left: -1em;
        }
    }
}

.include_kit_box {
    margin: 1em 0;
}

.your_kit_box {
    width: 100%;
    text-align: start;
    padding: 0 1em;
    &__title {
        color: $font_blue;
        font-size: 1.2em;
        font-weight: 600;
    }
    &__desc {
        font-size: 0.9em;
    }
}

.kit_content_box {
    padding: 1em 2.5em;
    &__title_box,
    &__price_box {
        display: flex;
        &__title {
            font-size: 1.2em;
            color: $font_light_blue;
            font-weight: 500;
        }
        &__content {
            font-size: 1.3em;
            color: $font_blue;
            font-weight: 600;
            margin-left: 0.5em;
        }
    }
    &__context_box {
        width: 100%;
        text-align: start;
        &__title {
            font-size: 1.3em;
            color: $font_blue;
            font-weight: 600;
        }
    }
    &__price_box {
        margin-top: 1.5em;
    }
}

.payment_method_box {
    width: 95%;
    margin: 0.5em auto;
}

.btn_box {
    width: 90%;
    padding: 3em 0 2em 0;
    margin: 0 auto;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
}

.details_container {
    position: relative;
    width: 60%;
    padding-top: 1.5em;
    padding-left: 1.5em;
    &__empty_box {
        position: relative;
        width: 98%;
        height: 100vh;
        margin: 0 auto;
        border-radius: $borderRadius;
        background: $bg-white;
        &__text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $font_grey;
        }
    }
}

.details {
    width: 98%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 11em;
    margin: 0 auto;
}

.your_kit_container,
.kit_detail_box {
    text-align: start;
    &__title {
        color: $font_blue;
        font-size: 1.2em;
        font-weight: 600;
        margin-bottom: 1em;
    }
    &__details {
        font-size: 0.9em;
        background: $bg-white;
        border-radius: $borderRadius;
        padding: 1em;
        &__title {
            color: $font_blue;
            font-size: 1.3em;
            font-weight: 600;
            margin-bottom: 0.3em;
        }
        &__content {
            margin-bottom: 1em;
        }
        &__state {
            display: flex;
            &__progress {
                padding: 0.2em 0.7em;
                font-size: 1em;
                border-radius: 5px;
                font-weight: 500;
                color: $font-completed;
                background: $bg-completed;
            }
            &__date {
                margin: auto 0;
                margin-left: 1em;
                color: $font_grey;
                font-weight: 300;
            }
        }
    }
}

.view_kit_box {
    background: $bg-white;
    border-radius: $borderRadius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    margin: 1em 0;
    cursor: pointer;
    &__title {
        color: $font_blue;
        font-weight: 600;
        font-size: 1.2em;
    }
    &__arrow_box {
        margin: auto 0;
        transform: scale(1.3);
        color: $font-light-blue;
        display: flex;
        align-items: center;
    }
}

.kit_detail_box {
    margin-top: 1.5em;
}

@include breakpoint(lg) {
    .main_content_box {
        width: 100%;
    }
}