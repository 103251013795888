@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.drawer_container {
    width: 100%;
    height: 100vh;
    padding: 2em;
    background: $bg-dashboard;
}

.drawer_title {
    width: 100%;
    display: flex;
    align-items: center;
    &__icon {
        transform: scale(1.3);
        cursor: pointer;
    }
    &__text {
        font-size: 1.5em;
        margin-left: 1em;
        font-weight: 600;
        color: $font_blue;
    }
}

.scrollable {
    width: 100%;
    height: 100%;
    overflow-y: auto;   
}

.notification_list_box {
    width: 100%;
    margin: 1.5em 0;
    &__title {
        font-size: 1.2em;
        font-weight: 500;
        color: $font_blue;
    }
    &__list {
        width: 100%;
    }
    &__empty_box {
        padding: 1em;
        font-size: 1.1em;
        color: $font_grey;
    }
}

.card_container {
    width: 25em;
    display: flex;
    align-items: center;
    margin: 0.5em 0;
    padding: 1em;
    border-radius: $borderRadius;
    transition: box-shadow 0.5s;
    &__icon {
        min-width: 3em;
    }
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2);
    }
}

.blue_bg {
    background: #618EFF1A;
}
.white_bg {
    background: $bg-white;
}

.notification_content {
    margin-left: 0.7em;
    font-size: 0.9em;
    cursor: pointer;
    &__type {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $font_blue;
        &__status {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            color: $font_light_blue;
            font-weight: 500;
        }
    }
    &__message {
        max-width: 315px;
        color: $font_black;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}