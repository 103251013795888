@import './global_variables.scss';

html {
    box-sizing: border-box;
}
  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
}

body {
    width: 100%;
    font-family: $space_grotesk;
}

.router_container {
  position: relative;
}

#root {
    height: 100%;
}

a {
    text-decoration: none;
}

// scroll bar
::-webkit-scrollbar {
    width: 5px;
  }
  
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba(47, 104, 250, 0.2); 
  border-radius: $borderRadius;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(47, 104, 250, 0.4); 
}