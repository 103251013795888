@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    padding: 1em;
    margin-bottom: 1em;
    justify-content: flex-start;
    align-items: center;
    &__icon {
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.4em;
        color: $font_blue;
        font-weight: 700;
        margin-left: 1em;
    }
}

.modal_desc_box {
    width: 98%;
    text-align: start;
    padding: 0 1em;
}

.professional_list_box {
    width: 100%;
    height: 100%;
    max-height: 27em;
    padding-bottom: 1em;
    overflow-x: hidden;
    overflow-y: auto;
}

.empty_professional_box {
    width: 95%;
    height: 5em;
    margin: 0 auto;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.1em;
}

.professional_list {
    width: 100%;
    height: 100%;
    max-height: 24em;
    &__card, 
    &__card_clicked {
        margin: 0.5em 1em ;
        &:hover {
            -webkit-box-shadow: 0 0 0 2px $border-blue;
            -moz-box-shadow: 0 0 0 2px $border-blue;
            box-shadow: 0 0 0 2px $border-blue;
            border-radius: $borderRadius;
        }
    }
    &__card_clicked {
        -webkit-box-shadow: 0 0 0 2px $border-blue;
        -moz-box-shadow: 0 0 0 2px $border-blue;
        box-shadow: 0 0 0 2px $border-blue;
        border-radius: $borderRadius;
    }
}

.payment_method_list_box {
    width: 100%;
    max-height: 25em;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1em;
    &__card {
        margin-bottom: 1em;
    }
}

.btn_box {
    width: 90%;
    margin: 0 auto;
    padding: 3em 0 2em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
}

.pro_select_btn_box {
    width: 90%;
    margin: 0 auto;
    padding: 1em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
}