@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.document_list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    &__uploading_box {
        position: relative;
        width: 12em;
        height: 12em;
        margin: 0.5em;
        background: rgba(199, 199, 199, 0.5);
        border-radius: 5px;
        &__circular, &__progress {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $font_blue !important;
        }
        &__circular {
            width: 100% !important;
            height: 100% !important;
            padding: 4.2em;
        }
    }
    &__image_box {
        position: relative;
        width: 12em;
        height: 12em;
        margin: 0.5em;
        &__file {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border: 1px solid $border-dark-grey;
            border-radius: 5px;
        }
        &__icon {
            position: absolute;
            top: 4%;
            left: 4%;
            background: #F86454;
            color: $font_white;
            fill: $font_white;
            border-radius: 50%;
            width: 1.8em;
            height: 1.8em;
            padding: 0.2em;
            cursor: pointer;
        }
    }

    &__add_box {
        width: 12em;
        height: 12em;
        &__icon_btn {
            position: relative;
            width: 3.5em;
            height: 3.5em;
            background: $user-icon !important;
            border-radius: 50%;
            margin-top: 2.5em !important;
            margin-left: 2.5em !important;
            cursor: pointer;
            &__file_input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                opacity: 0;
                cursor: pointer;
                z-index: 2;
            }
            &__image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1.3);
                z-index: 1;
            }
        }
    }
}

.file_list {
    width: 100%;    
}

.file_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    margin: 0.3em 0;
    &__title_box {
        display: flex;
        width: 100%;
        &__icon {
            margin: auto 1em;
        }
        &__text_box {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 100%;
            max-width: 20em;
            margin: 1em 0;
            &__top {
                color: $font_light_blue;
                font-size: 1.2em;
                font-weight: 400;
            }
            &__bottom {
                height: 100%;
                color: $font_black;
                font-size: 1em;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
    &__delete {
        margin: auto 0;
        margin-right: 1em;
        transform: scale(1.2);
        fill: #F86454;
        cursor: pointer;
    }
}

.progress_box {
    width: 100%;
    &__row {
        position: relative;
        background: $bg-dashboard;
        margin-bottom: 0.3em;
        border-radius: $btn-border-radius;
        display: flex;
        align-items: center;
        overflow: hidden;
        &__icon {
            color: $btn-light;
            margin: auto 1em;
        }
        &__content {
            height: 100%;
            width: 85%;
            margin: 1em 0;
            &__top {
                width: 100%;
                color: $font_light_blue;
                font-size: 1.2em;
                font-weight: 400;
                display: flex;
                justify-content: space-between;
            }
            &__progress {
                height: 0.4em;
                width: 100%;
                background: #fff;
                border-radius: $btn-border-radius;
                margin-top: 0.3em;
                &__bar {
                    height: 100%;
                    background: $bg-blue;
                    border-radius: inherit;
                }
            }
        }
    }
}

.attach_file_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 10px;
    cursor: pointer;
    z-index: 2;
}