@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.payment_method_container, 
.payment_method_selected_container {
    width: 100%;
    background: $bg-white;
    border-radius: $borderRadius;
    display: flex;
    transition: box-shadow .3s;
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2), inset 0 0 0 2px $border-blue;
    }
}

.payment_method_selected_container {
    -webkit-box-shadow: 0 0 0 2px $border-blue;
    -moz-box-shadow: 0 0 0 2px $border-blue;
    box-shadow: 0 0 0 2px $border-blue;
}

.profile_payment_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.left_side {
    display: flex;
}

.right_side {
    display: flex;
    justify-content: center;
    align-items: center;
}

.edit_icon {
    width: 2em;
    height: 2em;
    transform: scale(1.1);
    fill: $btn-dark;
    padding-right: 1vw;
    cursor: pointer;
}

.card_icon, .paypal_icon {
    margin: 2em;
    fill: $close-icon;
}

.card_icon {
    transform: scale(2.5);
}

.paypal_icon {
    transform: scale(1.3);
}

.payment_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__title {
        display: flex;
        color: $font_light_blue;
        font-size: 1.3em;
        font-weight: 500;
        &__active {
            margin-left: 0.5em;
            color: $font-completed;
        }
    }
    &__detail {
        font-size: 1.1em;
        color: $font_black;
    }
}