@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.stripe_container {
    width: 100%;
}

.StripeElement {
    font-family: $space_grotesk;
    height: 3em;
    padding: 0.7em 0.5em;
    width: 100%;
    color: $font_blue;
    border: 2px solid $border-blue;
    border-radius: $btn-border-radius;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}
  
.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}