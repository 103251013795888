@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_content_box {
    width: 80%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: $bg-dashboard;
}

.appointment_container {
    width: 40%;
    padding-top: 1.5em;
    padding-left: 1.5em;
}

.appointment_box {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 11em;
    &__active, &__compelted {
        width: 100%;
    }
}

.no_appointment_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5em;
    margin-right: 2vw;
    &__text {
        width: 80%;
        &__title {
            color: $font_blue;
            font-size: 1.7em;
            font-weight: 600;
        }
        &__desc {
            margin-top: 1em;
            color: $font_black;
        }
    }
    &__icon {
        width: 20%;
    }
}

.empty_appointment_box {
    height: 3em;
    border-radius: $borderRadius;
    background: $bg-white;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details_container {
    position: relative;
    width: 60%;
    padding-top: 1.5em;
    padding-left: 0.5em;

    &__detail_box {
        position: relative;
        width: 98%;
        height: 100%;
        border-radius: $borderRadius;
        background: $bg-white;

        &__empty_txt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $font_grey;
        }
    }
}

.details_box {
    width: 98%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 11em;
    margin: 0 auto;

    &__progress_title, &__detail_title {
        font-size: 1.2em;
        color: $font_blue;
        font-weight: 600;
    }

    &__detail_title {
        margin-top: 0.8em;
    }

    .tfa_button_container1 {
        height: 2.6em;
        width: 95%;
        margin: 0.6em 0;
        color: $font_light_blue;
        border: 2.5px solid $border-blue;
        &:hover {
            border: 2.5px solid $border-blue;
        }
    }

    .tfa_button_container2 {
        height: 2.6em;
        width: 95%;
        margin: 0.6em 0;
        background-color: $btn-light;
        &:hover {
            background-color: $btn-light;
        }
    }
}

.progress {
    &__progress_state {
        width: 100%;
        margin: 0.5em 0;
    }
}

.question_box {
    width: 95%;
    margin-top: 0.5em;
    padding: 0.5em;
    padding-left: 0.8em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    &__title {
        width: 90%;
        color: $font-blue;
        font-size: 1.5em;
        font-weight: 700;
    }
    &__content {
        width: 90%;
        font-size: 0.9em;
        margin: 0.5em 0;
    }
    &__complete_time {
        margin: 0.3em 0;
        color: $font_grey;
        font-size: 0.9em;
        font-weight: 300;
    }
}

.time_slot_box, .link_box {
    width: 95%;
    display: flex;
    margin-top: 0.6em;
    padding: 0.5em;
    padding-left: 0.8em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    align-items: center;
    &__title {
        color: $font_light_blue;
        font-size: 1.15em;
        font-weight: 500;
    }
    &__time, &__date, &__link {
        margin-left: 0.5em;
        font-size: 1.2em;
        font-weight: 700;
        color: $font_blue;
    }
    &__date, &__link {
        font-size: 1.1em;
        font-weight: 400;
    }
}

.link_box {
    position: relative;
    cursor: pointer;

    &__link {
        width: 60%; 
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis; 
    }

    &__icon {
        position: absolute;
        right: 2%;
    }
}

.chat_btn_box {
    .tfa_button_container1 {
        height: 2.6em;
        width: 95%;
        margin: 0.6em 0;
        color: $font_light_blue;
        border: 2.5px solid $border-blue;
        &:hover {
            border: 2.5px solid $border-blue;
        }
    }

    .tfa_button_container2 {
        height: 2.6em;
        width: 95%;
        margin: 0.6em 0;
        background-color: $btn-light;
        &:hover {
            background-color: $btn-light;
        }
    }

    .tfa_button_container3 {
        height: 2.6em;
        width: 95%;
        margin: 0.6em 0;
        background-color: $btn_grey;
        cursor: auto;
        &:hover {
            background-color: $btn_grey;
        }
    }
}

.professional_box {
    width: 95%;
    height: 5em;
    border-radius: $borderRadius;
    background: $bg-white;
    display: flex;
    &__avator {
        margin: auto 0.7em;
        border: 1px solid $border-icon;
    }
    &__professional_info {
        margin: auto 0;
        &__professional_name{
            color: $font_blue;
            font-weight: 500;
        }
        &__question_count {
            color: $font_black;
        }
    }
}

.button_text_box {
    width: 95%;
    text-align: center;
}

.cancel_box {
    width: 95%;
    border-radius: $borderRadius;
    color: $font-cancelled;
    background: $bg-cancelled;
    padding: 0.7em 1em;
    &__title {
        font-size: 1.1em;
        font-weight: 600;
    }
}

.cancel_detail_box {
    width: 95%;
    border-radius: $borderRadius;
    background: $bg-white;
    margin-top: 0.5em;
    padding: 0.5em 1em;
    &__name_box {
        display: flex;
        &__user {
            color: $font-blue;
            font-weight: 600;
            margin-right: 1vw;
        }
        &__time {
            color: $font_grey;
            font-weight: 500;
        }
    }
}

@include breakpoint(lg) {
    .main_content_box {
        width: 100%;
    }
    .state_icon {
        text-align: center;
        width: 17%;
        font-size: 0.9em;
    }
}

@include breakpoint(md) {
    .state_icon {
        font-size: 1.5vw;
    }
    .question_box {
        &__title {
            font-size: 2.5vw;
        }
    }
    .time_slot_box, .link_box {
        &__time, &__date, &__link, &__title {
            font-size: 0.9em;
        }
    }
}

@include breakpoint(sm) {
    .state_icon {
        width: 20%;
        font-size: 1.8vw;
    }
}