@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.text_editor .quill .ql-toolbar {
    border: none !important;
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.react_quill {
    &.ql-container {
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        background: #fefcfc;
    }
}