@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.content_box {
    width: 35em;
    margin: 0 auto;
    .tfa_text_wrapper1 {
        margin-top: 2em;
    }
    .primary_txt1 {
        width: 100%;
        font-size: 1.3em;
    }
    .secondary_txt1 {
        width: 90%;
        font-size: 2.5em;
        color: $font_blue;
    }
    .tfa_sub_text_primary {
        margin-top: 2em;
    }
}

.phone_input_box {
    margin-top: 1em;
}

.phone_number_font {
    font-weight: 600;
}

.send_code_box {
    margin-top: 2em;
    &__text {
        font-size: 1.2em;
        color: $font_blue;
        font-weight: 500;
        cursor: pointer;
    }
}

.avatar_box {
    position: relative;
    width: 100%;
    margin: 1em 0;
    &__avatar {
        margin: 0 auto;
        border: 4px solid $border-blue;
        background: $bg-blue !important;
    }
    &__camera {
        position: absolute !important;
        bottom: 0;
        right: 33%;
        &:hover {
            background: $user-icon !important;
        }
        &__avatar_input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
            &::-webkit-file-upload-button {
                cursor: pointer;
            }
        }
    }
}

.title_box {
    margin: 1.8em 0 0.8em 0;
    width: 100%;
    &__primary {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        color: $font_black;
        font-weight: 500;
        margin: 0.3em 0;
        width: 75%;
        @include breakpoint(lg){
            width: 90%;
        }
    }
}

.bullet_points {
    list-style: none;
}

.bullet_points li::before {
    content: "\2022";
    color: $font_light_blue;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -2em;
}

.tnc_box {
    margin-top: 4em;
    border: 2px solid $border-blue;
    border-radius: 6px;
    padding-left: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tnc_icon {
    margin-right: 1em;
    cursor: pointer;
}

.btn_box {
    width: 100%;
    padding: 4em 0 6em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
}