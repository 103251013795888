@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    .logo {
        position: absolute;
        top: 2.5%;
        left: 1%;
    }
    .main_container {
        margin: 2em auto;
        width: 40em;
        height: 100%;
        
        @include breakpoint(lg) {
            width: 28em;
        }
    }
}

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.main_content_container {
    width: 100%;
    .tfa_text_wrapper1{
        margin-top: 2em;
    }
    .primary_txt1 {
        font-size: 1.5em;
    }
    .secondary_txt1 {
        width: 80%;
        font-size: 2.5em;
        color: $font_blue;
    }
    .tfa_sub_text_primary {
        margin-top: 2em;
    }
}

.adjust_appointment_box {
    width: 100%;
    margin: 1em 0;
}

.editable_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $font_blue;
    fill: $font_blue;
}

.section_title {
    font-size: 1.3em;
    font-weight: 500;
}

.section_icon {
    padding: 0.2em ;
    transform: scale(1.2);
}

.professional_box,
.appointment_time_slot_box {
    width: 100%;
    margin-top: 0.4em;
}

.time_slot_box,
.appointment_price_box {
    width: 100%;
    background: $bg-dashboard;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    padding: 0.8em 1em;
    &__title {
        color: $font_light_blue;
        font-weight: 400;
        font-size: 1.3em;
    }
    &__time,
    &__price {
        color: $font_blue;
        font-weight: 600;
        font-size: 1.3em;
        margin: 0 0.4em;
    }
    &__date {
        color: $font_blue;
        font-weight: 400;
        font-size: 1.3em;
    }
}

.appointment_detail_container {
    width: 100%;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    margin-top: 0.3em;
    padding: 1em;
    padding-top: 0.1em;
}

.appointment_detail_box {
    margin-top: 1em;
    &__title {
        color: $font_light_blue;
        font-weight: 500;
    }
    &__content {
        margin-top: 0.3em;
        color: $font_black;
    }
}

.cancel_box {
    background: $bg-dashboard;
    border-radius: $borderRadius;
    margin-top: 0.4em;
    padding: 0.8em 1em;
    .tfa_button_container1 {
        height: 2.5em;
        width: 13em;
        background: $btn-delete;
        margin-top: 1em;
        &:hover {
            background: $btn-delete;
        }
    }
}

.btn_box {
    width: 100%;
    padding: 4em 0 6em 0;
    .tfa_button_container2 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
}