@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.1em;
        margin-left: 1em;
    }
}

.progress_box {
    width: 100%;
    &__title {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 500;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
    &__state {
        margin-bottom: 1em;
    }
    .state_container {
        justify-content: space-between;
    }
    .state_icon {
        text-align: center;
        width: 25%;
        padding: 0.4em;
    }
}

.search_box, 
.professional_detail_box,
.calendar_box {
    width: 100%;
    &__title {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 500;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
    }
    &__desc {
        margin-bottom: 1em;
    }
    &__card {
        padding: 0.2em;
    }
}

.professional_detail_box {
    &__title {
        margin-top: 0.5em;
    }
}

.empty_professional_box {
    width: 98%;
    height: 4em;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.professional_list_box, 
.appointment_time_box {
    height: 100vh;
    padding-bottom: 4em;
    overflow-x: hidden;
    overflow-y: auto;
}

.professional_card_container {
    margin: 0.5em 0;
    padding: 0 0.2em;
}

.asking_price_box {
    width: 100%;
    background: $bg-dashboard;
    border-radius: $borderRadius;
    padding: 1em;
    display: flex;
    margin-top: 0.3em;
    margin-bottom: 0.5em;
    &__title {
        color: $font_light_blue;
        font-size: 1.3em;
        font-weight: 300;
    }
    &__price {
        color: $font_blue;
        font-size: 1.3em;
        font-weight: 600;
        margin-left: 1em;
    }
}

.main_content_container {
    width: 100%;
    .tfa_text_wrapper1{
        margin-top: 2em;
    }
    .primary_txt1 {
        font-size: 1.5em;
    }
    .secondary_txt1 {
        width: 80%;
        font-size: 2.5em;
        color: $font_blue;
    }
    .tfa_sub_text_primary {
        margin-top: 2em;
    }
}

.title_box {
    margin: 1.8em 0 0.8em 0;
    width: 100%;
    &__primary {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        font-weight: 400;
        margin: 0.3em 0;
        width: 90%;
        &__bold {
            font-weight: 700;
        }
    }
    &__simple_input {
        width: 100%;
        padding-top: 1em;
    }
}

.btn_box {
    width: 100%;
    padding: 4em 0 6em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
}

.confirm_section_box {
    width: 100%;
    margin: 1em 0;
}

.editable_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $font_blue;
    fill: $font_blue;
}

.section_title {
    font-size: 1.3em;
    font-weight: 500;
}

.section_icon {
    padding: 0.2em ;
    transform: scale(1.2);
}

.professional_box,
.appointment_time_slot_box {
    width: 100%;
    margin-top: 0.4em;
}

.time_slot_box,
.appointment_price_box {
    width: 100%;
    background: $bg-dashboard;
    border-radius: $borderRadius;
    display: flex;
    align-items: center;
    padding: 0.8em 1em;
    &__title {
        color: $font_light_blue;
        font-weight: 400;
        font-size: 1.3em;
    }
    &__time,
    &__price {
        color: $font_blue;
        font-weight: 600;
        font-size: 1.3em;
        margin: 0 0.4em;
    }
    &__date {
        color: $font_blue;
        font-weight: 400;
        font-size: 1.3em;
    }
}

.appointment_detail_container {
    width: 100%;
    border-radius: $borderRadius;
    background: $bg-dashboard;
    margin-top: 0.3em;
    padding: 1em;
    padding-top: 0.1em;
}

.appointment_detail_box {
    margin-top: 1em;
    &__title {
        color: $font_light_blue;
        font-weight: 500;
    }
    &__content {
        margin-top: 0.3em;
        color: $font_black;
    }
}