@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    .logo {
        position: absolute;
        top: 2.5%;
        left: 1%;
    }
    .main_container {
        margin: 2em auto;
        width: 40em;
        height: 100%;
        
        @include breakpoint(lg) {
            width: 28em;
        }
    }
}