@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_container {
    width: 80%;
    height: 100vh;
    background: $bg-dashboard;
    overflow-y: auto;
    padding-bottom: 2em;
}

.setting_container {
    display: flex;
    margin-left: 2em;
    &__earnings, &__settings {
        width: 17em;
    }
    &__settings {
        margin-left: 1em;
    }
}

.account_detail_container {
    margin-left: 2em;
    display: flex;
    flex-wrap: wrap;
    max-width: 55em;
}

.main_content {
    width: 100%;
    height: 9em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
    padding-left: 1em;
    &__month {
        color: $font_yellow;
        font-size: 1.25em;
        font-weight: 500;
    }
    &__revenue {
        color: $font_blue;
        font-size: 1.8em;
        font-weight: 700;
        margin-top: 0.3em;
    }
}

.setting_title_box {
    display: flex;
    &__icon {
        margin-top: 1.5em;
        margin-bottom: 1em;
        align-items: center;
        transform: scale(1.5);
        fill: $font_blue;
        cursor: pointer;
        &:hover {
            fill: $font_light_blue;
        }
    }
}

.setting_list {
    width: 100%;
    height: 9em;
}

.setting_list {
    &__box, &__second_box {
        display: flex;
        width: 100%;
        padding: 0.75em 0.5em;
        border-radius: $borderRadius;
        background-color: $bg-white;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
            -webkit-box-shadow: 0 0 0 2px $border-blue;
            -moz-box-shadow: 0 0 0 2px $border-blue;
            box-shadow: 0 0 0 2px $border-blue;
        }
        &__title {
            padding-left: 0.3em;
            color: $font_blue;
            font-size: 1.1em;
            font-weight: 600;
        }
        &__arrow {
            margin: auto 0;
            color: $font_light_blue;
            transform: scale(1.1);
        }
    }
    &__second_box {
        margin: 0.25em 0;
    } 
}

.bank_container,
.bank_status_container,
.specialities_container,
.mara_status_container {
    margin-top: 1.5em;
    padding-right: 1.5em;
}

.account {
    display: flex;
    width: 25em;
    justify-content: space-between;
    padding: 1em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    &__details {
        display: flex;
        &__icon_big,
        &__status_icon_big {
            height: 100%;
            margin: auto 0;
            margin-left: 0.8em;
            transform: scale(1.6);
            fill: $font_blue;
        }
        &__status_icon_big {
            transform: scale(2.3);
        }
        &__text {
            margin-left: 1.5em;
            &__title {
                font-size: 1.1em;
                color: $font_light_blue;
                font-weight: 500;
            }
        }
    }
    &__icon_small {
        height: 100%;
        margin: auto 0;
        transform: scale(1.2);
        fill: $font_blue;
        cursor: pointer;
        &:hover{
            fill: $font_light_blue
        }
    }
}

.specialities_box {
    display: flex;
    width: 25em;
    padding: 1em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    &__details {
        display: flex;
        &__status_icon_big {
            height: 100%;
            margin: auto 0;
            margin-left: 1em;
            transform: scale(1.6);
            fill: $font_blue;
        }
        &__status_icon_big {
            transform: scale(2.3);
        }
        &__text {
            margin-left: 1.5em;
            &__title {
                font-size: 1.1em;
                color: $font_light_blue;
                font-weight: 500;
            }
        }
    }
}

.mara_box {
    display: flex;
    justify-content: space-between;
    width: 25em;
    padding: 1em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    &__contents {
        display: flex;
    }
    &__status_icon_big {
        height: 100%;
        margin: auto 0;
        margin-left: 1em;
        transform: scale(1.6);
        fill: $font_blue;
        transform: scale(2.3);
    }
    &__text_box {
        margin-left: 1.5em;
        &__container {
            display: flex;
            margin-bottom: 0.2em;
            &__title {
                width: 7.5em;
                font-size: 1.1em;
                color: $font_light_blue;
                font-weight: 500;
                margin-right: 1em;
            }
            &__detail {
                font-weight: 500;
                &__document {
                    height: 100%;
                    transform: scale(1.5);
                    margin-right: 0.5em;
                    cursor: pointer;
                    &:hover {
                        color: $font_light_blue;
                    }
                }
            }
            &__detail_active {
                color: #5BDB5B;
                font-size: 1.2em;
                font-weight: 600;
            }
            &__detail_inactive {
                color: #F86454;
                font-size: 1.2em;
                font-weight: 600;
            }
        }
    }
    &__edit_icon {
        height: 100%;
        margin: auto 0;
        transform: scale(1.2);
        fill: $font_blue;
        cursor: pointer;
        &:hover{
            fill: $font_light_blue
        }
    }
}

.bank_status_active,
.bank_status_rejected {
    font-size: 1.2em;
    font-weight: 500;
}

.bank_status_active {
    color: #5BDB5B;
}

.bank_status_rejected {
    color: #F86454;
}

.history_container {
    margin-left: 2em;
    margin-top: 2em;
}

.history_list {
    width: 98%;
    max-height: 20em;
    border-radius: $borderRadius;
    background-color: $bg-white;
    margin: 0.5em;
    overflow-y: auto;
    &:first-child {
        border: none;
    }
    &__box {
        width: 100%;
        padding: 0.8em;
        display: flex;
        border-top: 2px solid $border-grey;
        &__date {
            width: 15%;
        }
        &__question {
            width: 40%;
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis;
        }
        &__client, &__price, &__receipt {
            text-align: center;
            width: 15%;
        }
        &__receipt {
            color: $font_black;
        }
    }
}

// modal styles
.modal_title_box,
.mara_modal_title_box {
    position: relative;
    display: flex;
    width: 50%;
    margin: 1em 0;
    justify-content: center;
    &__icon {
        position: absolute;
        top: 9%;
        left: 7%;
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.3em;
        color: $font-blue;
        font-weight: 700;
    }
}

.mara_modal_title_box {
    width: 100%;
}

.account_detail_box, .meeting_availability_box {
    display: flex;
}

.detail_box, .profile_box {
    width: 50%;
    text-align: start;
    padding: 2em;
    padding-left: 1em;
    &__title {
        font-size: 1.2em;
        font-weight: 600;
        color: $font_blue;
    }
    &__desc {
        font-weight: 500;
    }
}

.availability_box {
    width: 60%;
    text-align: start;
    padding: 1em;
    &__title {
        font-size: 1.2em;
        font-weight: 600;
        color: $font_blue;
    }
    &__desc {
        font-weight: 500;
    }
}

.price_box {
    width: 40%;
    padding: 1em;
    padding-left: 0;
}

.asking_price_box, 
.question_price_box, 
.password_box,
.bank_account {
    width: 100%;
    text-align: start;
    padding-right: 1em;
    &__title {
        font-size: 1.2em;
        font-weight: 600;
        color: $font_blue;
    }
    &__desc {
        font-weight: 500;
    }
}

.password_box {
    margin-left: 1em;
}

.bank_account {
    padding: 0 1em;
}

.calendar_box {
    width: 100%;    
}

.avatar_box {
    position: relative;
    width: 100%;
    margin: 1em 0;
    &__avatar {
        margin: 0 auto;
        border: 4px solid $border-blue;
        background-color: $bg-blue !important;
    }
    &__camera {
        position: absolute !important;
        bottom: 0;
        right: 23%;
        &:hover{
            background: $user-icon !important;
        }
        &__avatar_input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }
    }
}

.modal_title_icon {
    width: 100%;
    height: 6em;
    margin: 2.5em 0 1em 0;
}

.modal_text_box {
    .secondary_txt2 {
        width: 100%;
        color: $font-blue;
        padding: 0 1em;
        font-size: 2.1em;
    }
    
    .description_txt2 {
        width: 100%;
        color: $font_black;
        padding: 0 2.9em;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.tfa_button_container1 {
    width: 50% !important;
    margin: 1em 0 !important;
    height: 3em;
    background-color: $btn-green !important;
    &:hover {
        background-color: $btn-green !important;
    }
}

.tfa_button_container2 {
    width: 93% !important;
    margin: 1em 0 !important;
    height: 2.5em;
    background-color: $btn-light !important;
    &:hover {
        background-color: $btn-light !important;
    }
}

.primary_txt1 {
    text-align: left;
    font-size: 1.2em;
    margin-top: 1.5em;
    margin-left: 1rem;
}

.secondary_txt1 {
    text-align: left;
    color: $font-blue;
    width: 100%;
    font-size: 2em;
    margin: 0.2em 0 1em 1rem;
}

.mara_contact_text {
    font-size: 0.8em;
    &__address {
        color: $font_blue;
        font-weight: 600;
    }
}

@include breakpoint(lg){
    .main_container {
        width: 100%;
    }
    .meeting_availability_box {
        display: block;
        height: 30em;
        overflow-y: auto;
    }
    .availability_box, .price_box {
        margin: 0 auto;
        width: 40em;
    }
}

@include breakpoint(md) {
    .modal_title_box {
        width: 100%;
    }
    .account_detail_box {
        display: block;
        height: 30em;
        overflow-y: auto;
    }
    .detail_box, .profile_box {
        margin: 0 auto;
        width: 80%;
    }
    .avatar_box {
        &__camera {
            right: 33%;
        }
    }
} 