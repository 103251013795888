@import '../../assets/styles/GlobalStyle/global_variables.scss';

.tfa_button_container {
    color: $font_white;
    height: 3.5em;
}

.disabled_circular_progress {
    color: $font_light_blue !important;
    padding: 0.3em;
}

.circular_progress {
    color: $font_white !important;
    padding: 0.3em;
}