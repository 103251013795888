@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_content_box {
    width: 80%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: $bg-dashboard;
}

.questions_container {
    width: 40%;
    padding-top: 1.5em;
    padding-left: 1.5em;
}

.no_question_box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5em;
    margin-right: 2vw;
    &__text {
        &__title {
            width: 8em;
            color: $font_blue;
            font-size: 1.7em;
            font-weight: 600;
        }
        &__desc {
            width: 18em;
            color: $font_black;
        }
    }
}

.question_list_container {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 7em;
}

.empty_question_box {
    width: 98%;
    height: 3em;
    border-radius: $borderRadius;
    background: $bg-white;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details_container {
    position: relative;
    width: 60%;
    padding-top: 1.5em;
    padding-left: 1.5em;

    &__detail_box {
        position: relative;
        width: 98%;
        height: 100vh;
        margin: 0 auto;
        border-radius: $borderRadius;
        background: $bg-white;

        &__empty_txt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $font_grey;
        }
    }
}

.details {
    width: 98%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 11em;
    margin: 0 auto;
}

.offer_box,
.your_question_box, 
.professional_box, 
.question_log_box {
    width: 100%;
    margin-top: 1.3em;
}

.detail_section_title {
    font-size: 1.2em;
    color: $font_blue;
    font-weight: 500;
}

.log_box {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 0.5em 0 1em 0;
}

.offer_box {
    &__title {
        font-size: 1.2em;
        color: $font_blue;
        font-weight: 500;
    }
}

.your_question_box {
    &__paid_price_box {
        width: 98%;
        border-radius: 10px;
        background: $bg-white;
        margin: 0.8em 0;
        padding: 1em;
        display: flex;
        &__title {
            color: $font_light_blue;
            font-size: 1.2em;
        }
        &__amount {
            color: $font-completed;
            font-size: 1.2em;
            margin-left: 0.5em;
            font-weight: 500;
        }
    }
}

.question_log_box {
    &__container {
        width: 98%;
        border-radius: 10px;
        background: $bg-white;
        margin: 0.5em 0;
        padding: 1em;
        text-align: center;
        align-items: center;
        &__pro_name {
            display: flex;
            &__name {
                color: $font_blue;
                font-weight: 500;
                margin: auto 0;
            }
            &__pro {
                color: $font_blue;
                font-weight: 300;
                margin: auto 0;
            }
            &__date {
                color: $font_grey;
                font-weight: 300;
                margin: auto 0.7em;
            }
        }
        &__question {
            text-align: start;
            font-weight: 300;
            margin: 0.3em 0;
            &__confirmation {
                margin: 0.3em 0;
                &__title {
                    color: $font_blue;
                    font-weight: 500;
                    font-size: 1.1em;
                }
                &__desc {
                    margin-bottom: 1em;
                }
            }
        }
    }
}

.answer_input_box {
    position: absolute;
    bottom: 0;
    max-height: 20em;
    width: 96%;
    background: $bg-dashboard;
    transition: height 500ms;
}

.attached_file_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.3em;
    &__file_chip {
        margin: 0.3em !important;
        padding: 0.2em !important;
        background: rgb(47,104,250, 0.3) !important;
        & span {
            padding-left: 0.3em !important;
            font-size: 1.1em !important;
            font-weight: 500 !important;
        }
    }
    &__clip_icon {
        transform: rotate(-90deg);
        margin: 0 0.5em;
        fill: $font_blue;
    }
}

// Modal
.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    margin: 1em 0;
    justify-content: center;
    &__icon {
        position: absolute;
        top: 9%;
        left: 7%;
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 700;
    }
}

.title_description {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1em;
    text-align: start;
}

.offer_detail_box {
    width: 90%;
    border-radius: $borderRadius;
    background: $bg-white;
    margin: 0 auto;
    margin-bottom: 1em;
    text-align: start;
}

.professional_box, .professional_modal_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    &__avatar {
        margin: auto 0;
        border: 1px solid $border-icon;    
    }
    &__profile {
        margin: auto 0;
        margin-left: 1em;
        &__name {
            color: $font_blue;
            font-weight: 500;
            &__code {
                font-weight: 300;
            }
        }
        &__detail {
            color: $font_black;
        }
    }
}

.professional_modal_box {
    flex-direction: row;
    padding: 1em;
}

.offer_content {
    padding: 0 1em;
    &__message {
        width: 100%;
    }
    &__price_box {
        width: 100%;
        padding: 0.7em 0;
        display: flex;
        &__title {
            color: $font_light_blue;
            font-size: 1.3em;
            font-weight: 500;
        }
        &__price {
            color: $font_blue;
            font-size: 1.3em;
            font-weight: 600;
            margin-left: 0.5em;
        }
    }
}

.title_box {
    width: 90%;
    margin: 0.5em auto;
    margin-bottom: 1em;
    text-align: start;
    &__primary {
        font-size: 1.1em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        font-weight: 400;
        margin-bottom: 0.3em;
    }
    &__difference_box {
        display: flex;
        background: $bg-white;
        border-radius: $borderRadius;
        padding: 1em;
        &__title {
            color: $font_light_blue;
            font-size: 1.3em;
            font-weight: 500;
        }
        &__price {
            color: $font_blue;
            font-size: 1.3em;
            font-weight: 600;
            margin-left: 0.5em;
        }
    }
}

.btn_box {
    width: 90%;
    margin: 0 auto;
    padding: 3em 0 2em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
    .tfa_button_container2 {
        height: 3em;
        background: $btn-delete;
        &:hover {
            background: $btn-delete;
        }
    }
}

.modal_icon {
    width: 100%;
    height: 6em;
    margin: 2.5em 0 1em 0;
}

.modal_text_box {
    .secondary_txt2 {
        width: 100%;
        color: $font-blue;
        padding: 0 1em;
        font-size: 2.1em;
    }
    
    .description_txt2 {
        width: 100%;
        color: $font_black;
        padding: 0 2.5em;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.confirm_btn_box {
    .tfa_button_container1 {
        width: 23em;
    }
    .tfa_button_container2 {
        width: 11em;
    }
    .tfa_button_container1, .tfa_button_container2 {
        background: $btn-light;
        height: 3em;
        font-size: 1em;
        margin: 1em 0;
        &:hover {
            background: $btn-light;
        }
    }
    &__go_back_box {
        cursor: pointer;
        margin: 0.5em 0 2em 0;
        &__txt {
            color: $font-blue;
            font-weight: 500;
        }
    }
}

@include breakpoint(lg) {
    .main_content_box {
        width: 100%;
    }
}