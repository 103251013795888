@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.modal_icon {
    width: 100%;
    height: 6em;
    margin: 2.5em 0 1em 0;
}

.modal_text_box {
    .secondary_txt1 {
        width: 100%;
        color: $font-blue;
        padding: 0 1em;
        font-size: 2.1em;
    }
    
    .description_txt1 {
        width: 100%;
        color: $font_black;
        padding: 0 2.9em;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.confirm_btn_box {
    .tfa_button_container1 {
        width: 11em;
    }
    .tfa_button_container1 {
        background: $btn-light;
        height: 3em;
        font-size: 1em;
        margin: 1em 0;
        &:hover {
            background: $btn-light;
        }
    }
}

.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    padding: 1em 2em;
    justify-content: flex-start;
    align-items: center;
    &__icon {
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.4em;
        color: $font_blue;
        font-weight: 700;
        margin-left: 1em;
    }
}

.modal_desc_box {
    width: 98%;
    text-align: start;
    padding: 0 2em;
}

.title_box {
    width: 100%;
    padding: 1em 2em;
    text-align: start;
    &__primary {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 500;
    }
    &__secondary {
        font-weight: 400;
        margin: 0.3em 0;
        margin-bottom: 0.5em;
        width: 90%;
        &__bold {
            font-weight: 700;
        }
    }
}

.btn_box {
    width: 90%;
    margin: 0 auto;
    padding: 1em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
}

.cancel_box {
    text-align: start;
    padding: 1em;
    .tfa_button_container1 {
        height: 2.5em;
        width: 13em;
        background: $btn-delete;
        margin-top: 1em;
        &:hover {
            background: $btn-delete;
        }
    }
}