@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.card_container, .clicked_container {
    width: 97%;
    display: flex;
    background: $bg-white;
    margin: 0.5em 0;
    margin-left: 0.15em;
    padding: 0.5em 0.8em;
    border-radius: $borderRadius;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card_container:hover {
    background-color: $bg-gray;
}

.clicked_container {
    -webkit-box-shadow: 0 0 0 2px $border-blue;
    -moz-box-shadow: 0 0 0 2px $border-blue;
    box-shadow: 0 0 0 2px $border-blue;
}

.content_box {
    width: 97%;
    padding: 0.3em 0;
    &__chat_box {
        display: flex;
        justify-content: space-between;
        font-size: 1.15em;
        color: $font-light-blue;
        margin-bottom: 0.4em;
        &__text {
            margin: auto 0;
            margin-right: 1em;
            font-weight: 500;
            &__time {
                margin-left: 0.5em;
                font-size: 1.1em;
                font-weight: 700;
                color: $font_blue;
            }
        }
    }
    &__title_box {
        margin: 0.3em 0;
        font-size: 1.45em;
        font-weight: 600;
        color: $font_blue;
        &__text {
            width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    &__client_box {
        display: flex;
        margin: 0.4em 0;
        color: $font_blue;
        &__title {
            font-weight: 400;
        }
        &__name {
            margin: 0 0.8em;
            font-weight: 600;
        }
    }
}

.arrow_box {
    margin: auto 0;
    margin-left: 0.5em;
    transform: scale(1.3);
    color: $font-light-blue;
    &__icon {
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}

@include breakpoint(lg){
    .content_box {
        &__chat_box {
            font-size: 1.4vw;
        }
        &__title_box {
            font-size: 1.7vw;
        }
        &__client_box {
            font-size: 1.3vw;
        }
    }
}

@include breakpoint(md){
    .content_box {
        &__chat_box {
            font-size: 1.2vw;
        }
        &__title_box {
            font-size: 1.85vw;
        }
    }
    .arrow_box {
        transform: scale(0.8);
    }
}