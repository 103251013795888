@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    position: relative;
    height: 100vh;
    width: 100%;

    .logo {
        position: absolute;
        top: 0;
        left: 2%;
    }

    &__main_container {
        margin: 4em auto;
        width: 30em;
        height: 100%;
        overflow: hidden;

        .link_container1 {
            font-size: 1.3em;
        }

        &__tfa_text {
            margin: 4em 0 1.5em 0;

            .primary_txt1 {
                font-size: 1.6em;
            }

            .secondary_txt1 {
                color: $font-blue;
                width: 100%;
                font-size: 2.8em;
                margin: 0.2em 0;
            }
        }

        .tfa_button_container1 {
            background: $btn-light;
            width: 25em;
            height: 2.5em;
            margin: 5em 0 !important;

            &:hover {
                background: $btn-light;
            }
        }
    }
}

.text_wrapper {
    width: 100%;
    margin-bottom: 0.5em;

    &__title{
        color: $font-blue;
        font-size: 1.5em;
        margin-bottom: 0.3em;
    }

    &__description {
        color: $font-black;
        font-size: 1.1em;
    }
}

.backBtn_box {
    display: flex;
    width: fit-content;
    color: $font_blue;
    cursor: pointer;
    &__arrow {
        margin: auto 0;
        transform: scale(1.6);
    }
    &__txt {
        font-weight: 500;
        font-size: 1.3em;
        margin-left: 1em;
    }
}