@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.availability_box {
    width: 100%;
    height: 100%;
    padding: 1em 0;
    background: $bg-dashboard;
    border-radius: $borderRadius;
}

.month_text {
    color: $font_blue;
    font-weight: 700;
    font-size: 1.6em;
    padding: 0.8em;
    padding-top: 0.5em;
}

.calendar_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0 0.3em;
}

.dayOfWeek_row,
.empty_cell,
.day_cell {
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dayOfWeek_row {
    font-size: 1.1em;
}

.empty_cell, .day_cell {
    margin: 0.2em 0;
}

.day_cell {
    font-weight: 700;
    font-size: 1.3em;
    cursor: pointer;
}

.available_unavailable {
    background: $bg-dashboard;
    color: rgba(248, 100, 84, 0.5);
    cursor: not-allowed;
}

.available_none,
.available_left,
.available_right,
.available_both {
    background: rgba(97, 142, 255, 0.6);
    color: $font_white;
}

.available_none {
    border-radius: 20px;
}

.available_left {
    border-radius: 0 20px 20px 0;
    margin-right: 0.1em;
}

.available_right {
    border-radius: 20px 0 0 20px;
    margin-left: 0.1em;
}

.disabled_day {
    background: $bg-dashboard;
    color: $font_grey;
    cursor: not-allowed;
}

.selected_cell {
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.7em;
        height: 1.7em;
        background: $font-completed;
        opacity: 0.5;
        border-radius: 50%;
    }
}