@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.main_content_box {
    width: 80%;
    height: 100vh;
    display: flex;
    overflow: hidden;
    background: $bg-dashboard;
}

.questions_container {
    width: 50%;
    padding-top: 1.5em;
    padding-left: 1.5em;
}

.job_container {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 11em;
}

.details_container {
    position: relative;
    width: 50%;
    padding-top: 1.5em;

    &__detail_box {
        position: relative;
        width: 98%;
        height: 100vh;
        margin: 0 auto;
        border-radius: $borderRadius;
        background: $bg-white;

        &__empty_txt {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $font_grey;
        }
    }

    &__flag_icon {
        position: absolute;
        right: 6%;
        top: 2%;
        transform: scale(1.1);
        cursor: pointer;
    }
}

.empty_question_box {
    width: 98%;
    height: 3em;
    border-radius: $borderRadius;
    background: $bg-white;
    color: $font_grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details {
    width: 98%;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 11em;
    margin: 0 auto;
}

.log_title {
    color: $font-blue;
    font-weight: 600;
    font-size: 1.1em;
    margin-top: 0.5em;
    margin-left: 0.2em;
}

.log_box {
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 0.5em 0 1em 0;
}

.answer_bg {
    position: absolute;
    max-height: 20em;
    width: 96%;
    bottom: 0;
    background: $bg-dashboard;
    transition: height 500ms;
}

.attached_file_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0.3em;
    &__file_chip {
        margin: 0.3em !important;
        padding: 0.2em !important;
        background: rgb(47,104,250, 0.3) !important;
        & span {
            padding-left: 0.3em !important;
            font-size: 1.1em !important;
            font-weight: 500 !important;
        }
    }
    &__clip_icon {
        transform: rotate(-90deg);
        margin: 0 0.5em;
        fill: $font_blue;
    }
}


// modal styles
.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    margin: 1em 0;
    justify-content: center;
    &__icon {
        position: absolute;
        top: 9%;
        left: 7%;
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.3em;
        color: $font_blue;
        font-weight: 700;
    }
}

.modal_description {
    color: $font_black;
    margin: 0.5em 0;
}

.modal_template_list {
    max-height: 220px;
    overflow-y: scroll;
    overflow-x: hidden;
    &__box {
        display: flex;
        background: $bg-white;
        width: 95%;
        margin: 0.7em auto;
        padding: 1em 0.5em;
        border-radius: $borderRadius;
        text-align: start;
        cursor: pointer;
        &__title{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            padding-left: 0.3em;
            color: $font_blue;
            font-size: 1.2em;
            font-weight: 600;
            width: 95%;
        }
        &__arrow {
            justify-content: end;
            margin: auto 0;
            color: $font_light_blue;
            transform: scale(1.1);
            width: 5%;
        }
        &:hover {
            -webkit-box-shadow: 0 0 0 2px $border-blue;
            -moz-box-shadow: 0 0 0 2px $border-blue;
            box-shadow: 0 0 0 2px $border-blue;
        }
    }
    &__selected {
        -webkit-box-shadow: 0 0 0 2px $border-blue;
        -moz-box-shadow: 0 0 0 2px $border-blue;
        box-shadow: 0 0 0 2px $border-blue;
    }
}

.modal_icon {
    width: 100%;
    height: 6em;
    margin: 2.5em 0 1em 0;
}

.modal_flag_box {
    text-align: left;
    margin-bottom: 1.5em;
    .secondary_txt1 {
        width: 90%;
        color: $font-blue;
        font-size: 1.3em;
        margin: 0 auto;
        font-weight: 400;
    }
    
    .description_txt1 {
        width: 90%;
        color: $font_black;
        font-size: 0.9em;
        margin: 0 auto;
    }
}


.modal_text_box {
    .secondary_txt2 {
        width: 100%;
        color: $font-blue;
        padding: 0 1em;
        font-size: 2.1em;
    }
    
    .description_txt2 {
        width: 100%;
        color: $font_black;
        padding: 0 2.9em;
        font-size: 1.1em;
        font-weight: 500;
    }
}

.confirm_btn_box {
    .tfa_button_container1 {
        width: 23em;
    }
    .tfa_button_container2 {
        width: 11em;
    }
    .tfa_button_container1, .tfa_button_container2 {
        background: $btn-light;
        height: 3em;
        font-size: 1em;
        margin: 1em 0;
        &:hover {
            background: $btn-light;
        }
    }
    &__go_back_box {
        cursor: pointer;
        margin: 0.5em 0 2em 0;
        &__txt {
            color: $font-blue;
            font-weight: 500;
        }
    }
}

@include breakpoint(lg) {
    .main_content_box {
        width: 100%;
    }
}