@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    padding: 1em;
    margin-bottom: 1em;
    justify-content: flex-start;
    align-items: center;
    &__icon {
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.4em;
        color: $font_blue;
        font-weight: 700;
        margin-left: 1em;
    }
}

.modal_desc_box {
    width: 98%;
    text-align: start;
    padding: 0 1em;
}

.selected_date_box {
    width: 100%;
    padding: 1em;
    display: flex;
    &__title {
        color: $font_light_blue;
        font-weight: 500;
        font-size: 1.3em;
    }
    &__date {
        color: $font_blue;
        font-weight: 500;
        font-size: 1.3em;
        margin-left: 1em;
    }
}

.available_box {
    width: 100%;
    height: 20em;
    overflow-y: auto;
    overflow-x: hidden;
    &__time_card,
    &__time_card_selected {
        width: 98%;
        display: flex;
        justify-content: center;
        background: $bg-dashboard;
        border-radius: $borderRadius;
        margin: 0.5em auto;
        padding: 0.8em 0;
        cursor: pointer;
        transition: box-shadow 0.5s;
        p {
            color: $font_blue;
            font-weight: 700;
            font-size: 1.2em;
            margin: 0 0.5em;
        }
        &:hover {
            -webkit-box-shadow: 0 0 0 2px $border-blue;
            -moz-box-shadow: 0 0 0 2px $border-blue;
            box-shadow: 0 0 0 2px $border-blue;
        }
    }
    &__time_card_selected {
        -webkit-box-shadow: 0 0 0 2px $border-blue;
        -moz-box-shadow: 0 0 0 2px $border-blue;
        box-shadow: 0 0 0 2px $border-blue;
    }
}

.btn_box {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-light;
        &:hover {
            background: $btn-light;
        }
    }
    .tfa_button_container2 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
}

.payment_method_list_box {
    width: 100%;
    padding: 1em;
    &__card {
        margin-bottom: 1em;
    }
}