@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.progress_box {
    width: 100%;
    margin-bottom: 1em;
    &__title {
        font-size: 1.2em;
        color: $font_blue;
        font-weight: 500;
    }
    &__progress_msg {
        margin: 0.5em 0;
    }
    &__progress {
        width: 100%;
        margin: 0.5em 0;
    }
}

@include breakpoint(xlg) {
    .state_container {
        justify-content: space-between;
    }
    .state_icon {
        text-align: center;
        width: 20%;
        font-size: 0.9em;
    }
}

@include breakpoint(lg) {
    .state_icon {
        font-size: 1.34vw;
    }
    .progress_box {
        &__progress_msg {
            font-size: 0.9em;
        }
    }
}

@include breakpoint(sm) {
    .state_icon {
        font-size: 1.32vw;
    }
}