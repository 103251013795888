@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.container {
    width: 98%;
    border-radius: 10px;
    background: $bg-white;
    margin: 0.5em 0;
    padding: 1em;
    &__professional {
        width: 100%;
        display: flex;
        &__avatar {
            margin: auto 0;
            border: 1px solid $border-icon;
        }
        &__profile {
            margin: auto 0;
            margin-left: 1em;
            &__name {
                color: $font_blue;
                font-weight: 500;
                &__code {
                    font-weight: 300;
                }
            }
            &__detail {
                color: $font_black;
            }
        }
    }
    &__content {
        margin-top: 0.5em;
        &__message {
            width: 100%;
        }
        &__price_box {
            width: 100%;
            margin: 0.7em 0;
            display: flex;
            &__title {
                color: $font_light_blue;
                font-size: 1.3em;
                font-weight: 500;
            }
            &__price {
                color: $font_blue;
                font-size: 1.3em;
                font-weight: 600;
                margin-left: 0.5em;
            }
        }
    }
    &__options {
        display: flex;
        &__accept_btn, &__decline_btn {
            margin-top: 0.5em;
            margin-right: 1em;
            padding: 0.4em 0.7em;
            border-radius: 5px;
            font-weight: 600;
            cursor: pointer;
            transition: box-shadow 0.3s;
            &:hover {
                box-shadow: 0 0 11px rgba(33,33,33,.2);
            }
        }
        &__accept_btn {
            color: $font-completed;
            background: $bg-completed;
        }
        &__decline_btn {
            color: $font-cancelled;
            background: $bg-cancelled;
        }
    }
}