@import '../../../../assets/styles/GlobalStyle/global_variables.scss';

.auth_wrapper {
    display: flex;
    overflow: hidden;
}

.left_container {
    width: 50%;
    height: 100vh;

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.right_container {
    position: relative;
    width: 50%;
    height: 100vh;

    .link_container1 {
        font-size: 1.1em;
    }
    
    .backward1 .aiforward1  {
        transform: scale(1.7);
    }

    @include breakpoint(lg){
        .link_container1 {
            font-size: 0.9em;
        }
        
        .backward1 .aiforward1  {
            transform: scale(1.5);
        }
    }

    &__title {
        display: flex;
        width: 100%;
        margin-top: 2vw;
        justify-content: space-between;

        .tfa_text_wrapper1 {
            width: 70%;
        }

        .primary_txt1 {
            font-size: 1.3em;
        }

        .secondary_txt1 {
            color: $font-blue;
            width: 100%;
            font-size: 2.2vw;
        }

        .user_icon {
            width: 4.3vw;
            height: 4.3vw;
            color: $user-icon;
        }

        @include breakpoint(lg){
            .tfa_text_wrapper1 {
                width: 80%;
            }
            .secondary_txt1 {
                font-size: 1.8em;
            }
            .user_icon {
                width: 4vw;
                height: 4vw;
            }
        }

        @include breakpoint(md){
            .primary_txt1 {
                font-size: 1.2em;
            }
            .secondary_txt1 {
                font-size: 2.8vw;
            }
            .user_icon {
                width: 5vw;
                height: 5vw;
            }
        }
        
        @include breakpoint(sm){
            .primary_txt1 {
                font-size: 1em;
            }
            .secondary_txt1 {
                font-size: 3vw;
            }
        }
    }
}

.center_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
}

.forgotPwd_text {
    width: 16em;
    font-size: 1vw;
    margin: 2em 0;
    margin-bottom: 0.8em;
    font-weight: 500;
}

.auth_btn_wrapper {
    width: 100%;
    margin-top: 3em;
    display: block;

    .tfa_button_container1 {
        background: $btn-light;
        height: 2.5em;

        &:hover {
            background: $btn-light;
        }
    }

    .tfa_button_container2 {
        color: $btn-light;
        background: $btn-white;
        border: 2.5px solid $btn-light;
        height: 2.5em;
        margin: 1em 0;

        &:hover {
            background: $btn-white;
        }
    }

    @include breakpoint(lg) {
        .tfa_button_container1 {
            font-size: 1em !important;
        }
        
        .tfa_button_container2 {
            font-size: 1.4vw !important;
        }
    }

    @include breakpoint(md){
        .tfa_button_container2 {
            font-size: 1.3vw !important;
            height: 3.5em !important;
        }
    }
}

// All the other classes
@include breakpoint(lg){
    .forgotPwd_text {
        font-size: 1.2vw;
    }
}

@include breakpoint(md){
    .forgotPwd_text {
        font-size: 1.5vw;
    }
}