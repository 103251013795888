@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

// font
$space_grotesk: 'Space Grotesk', sans-serif;

// font color
$font_white: #FFFFFF;
$font_gold: #FFDEA8;
$font_yellow: #D1A969;
$font_blue: #022873;
$font_light_blue: #2F68FA;
$font_black: #1F1F1F;
$font_grey: #C7C7C7;

// background colors
$bg-primary: #0554F2;
$bg-blue: #618EFF;
$bg-gold: #FFDEA8;
$bg-dashboard: #F2F6F7;
$bg-white: #FFFFFF;
$bg-gray: rgba(216, 216, 216, 0.2);

// icon colors
$user-icon: #2F68FA;
$close-icon: #022873;

// btn colors
$btn-primary: #0554F2;
$btn-light: #2F68FA;
$btn-dark: #022873;
$btn-white: #FFFFFF;
$btn-green: #5BDB5B;
$btn-delete: #F86454;
$btn_grey: #C7C7C7;

// status font colors
$font-completed: #5BDB5B;
$font-active: #E8C244;
$font-uncompleted: rgba(47, 104, 250, 0.2);
$font-cancelled: #F86454;

// status background colors
$bg-completed: rgba(91, 219, 91, 0.2);
$bg-active: rgba(232, 194, 68, 0.2);
$bg-uncompleted: #F2F6F7;
$bg-cancelled: rgba(248, 100, 84, 0.2);

// category font colors
$font-country: #D1A969;
$font-category: #2F68FA;

// category background colors
$bg-country: rgba(209, 169, 105, 0.2);
$bg-category: rgba(47, 104, 250, 0.2);

// border radius
$borderRadius: 10px;
$btn-border-radius: 6px;

// border colors
$border-grey: #F2F6F7; 
$border-icon: #002267;
$border-dark-grey: #C7C7C7;
$border-blue: #2F68FA;
$border-green: #5BDB5B;

@mixin breakpoint($point) {
    @if $point == xsm {
        @media (max-width: 700px){
            @content;
        }
    }
    @if $point == sm {
        @media (max-width: 768px){
            @content;
        }
    }
    @if $point == md {
        @media (max-width: 1024px){
            @content;
        }
    }
    @if $point == lg {
        @media (max-width: 1250px){
            @content;
        }
    }
    @if $point == xlg {
        @media (max-width: 1440px){
            @content;
        }
    }
}