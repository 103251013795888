@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.wrapper {
    width: 100%;
    border-radius: $btn-border-radius;
    position: relative;
}

.form_box {
    width: 100%;
    display: flex;
    border: 2px solid $border-blue;
    border-radius: $btn-border-radius;
    padding: 0.7em 0;
    margin-bottom: 0.3em;
    cursor: pointer;
    &__upload_icon {
        transform: rotate(-90deg);
        margin: 0 0.5em;
        fill: $font_blue;
    }
    &__upload_text {
        color: $font_blue;
        font-weight: 700;
        font-size: 1.15em;
    }
}

.file_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3.65em;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.progress_box {
    width: 100%;
}

.uploaded_box {
    width: 100%;
    max-height: 250px;
    overflow: auto;
}

.row {
    position: relative;
    background: $bg-dashboard;
    margin-bottom: 0.3em;
    padding: 1em 1.2em;
    list-style: none;
    border-radius: $btn-border-radius;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    &__content {
        width: 100%;
        margin-left: 1em;
        &__details {
            max-width: 400px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5em;
        }
    }
    &__progress_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__details {
            max-width: 300px;
            overflow: hidden;
            margin: 0 1em;
            display: flex;
            flex-direction: column;
        }
    }
    &__content, &__progress_content {
        font-weight: 500;
        &__details {
            &__name {
                font-size: 1.1em;
            }
        }

        &__progress_bar {
            height: 0.4em;
            width: 100%;
            background: #fff;
            border-radius: $btn-border-radius;
            margin-bottom: 0.3em;
            &__progress {
                height: 100%;
                background: $bg-blue;
                border-radius: inherit;
            }
        }
    }
}

.document_icon, .complete_icon {
    transform: scale(2);
    color: $btn-light;
}

.delete_file {
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(1.5);
    color: $close-icon;
    cursor: pointer;
}