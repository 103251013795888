@import '../../assets/styles/GlobalStyle/global_variables.scss';

.tfa_sub_text_wrapper {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 0.3em;
    .tfa_sub_text_primary { 
        color: $font-blue;
        font-size: 1.2em;
        font-weight: 500;
    }

    .tfa_sub_text_description {
        width: 80%;
        color: $font-black;
        margin-top: 0.2em;
    }
}