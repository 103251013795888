@import '../../assets/styles/GlobalStyle/global_variables.scss';

.box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $borderRadius;
    background: $bg-dashboard;
    border: none ;
    outline: none;
    text-align: center;
}