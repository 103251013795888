@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.card_container, .clicked_container {
    width: 97%;
    display: flex;
    justify-content: space-between;
    background: $bg-white;
    margin: 0.8em 0;
    margin-left: 0.15em;
    padding: 0.5em 0.8em;
    border-radius: $borderRadius;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card_container:hover {
    background-color: $bg-gray;
}

.clicked_container {
    -webkit-box-shadow: 0 0 0 2px $border-blue;
    -moz-box-shadow: 0 0 0 2px $border-blue;
    box-shadow: 0 0 0 2px $border-blue;
}

.card_main_box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.subclass_box {
    display: flex;
    align-items: center;
    &__title {
        font-size: 1.2em;
        color: $font-light-blue;
        font-weight: 600;
    }
    &__value {
        font-size: 1.2em;
        color: $font_blue;
        font-weight: 700;
        margin-left: 0.5em;
    }
}

.kit_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.45em;
    font-weight: 600;
    color: $font_blue;
    overflow-y: hidden;
}

.state_box {
    display: flex;
    margin: 0.3em 0;
    &__purchased_date {
        margin: auto 0;
        margin-left: 1em;
        color: $font_grey;
        font-weight: 300;
    }
}

.purchased_state,
.available_state {
    padding: 0.2em 0.7em;
    font-size: 1em;
    border-radius: 5px;
    font-weight: 500;
}

.purchased_state {
    color: $font-completed;
    background: $bg-completed;
}

.available_state {
    color: $font-active;
    background: $bg-active;
}

.arrow_box {
    margin: auto 0;
    transform: scale(1.3);
    color: $font-light-blue;
}

@include breakpoint(lg) {
    .kit_title {
        font-size: 1.9vw;
    }
    .state_box {
        &__purchased_date {
            font-size: 1.2vw;
        }
    }
}

@include breakpoint(md) {
    .kit_title {
        font-size: 2vw;
    }
    .arrow_box {
        transform: scale(1);
    }
}