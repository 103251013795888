@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.title_box {
    display: flex;
    width: 100%;
    margin-bottom: 1.3em;
    justify-content: space-between;
    .title {
        font-size: 2em;
        font-weight: 500;
        color: $font_blue;
    }
    
    .icon {
        transform: scale(1.4);
        fill: $btn-dark;
        margin: auto 0;
        margin-right: 1vw;
        cursor: pointer;
    }
}


@include breakpoint(md){
    .title_box {
        .title {
            font-size: 1.6em;
        }
    }
}