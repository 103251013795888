@import '../../../assets/styles/GlobalStyle/global_variables.scss';

// working area, my questions
.card_container, .clicked_container {
    width: 97%;
    display: flex;
    justify-content: space-between;
    background: $bg-white;
    margin: 0.8em 0;
    margin-left: 0.15em;
    padding: 0.5em 0.8em;
    border-radius: $borderRadius;
    cursor: pointer;
    transition: background-color 0.3s;
}

.card_container:hover {
    background-color: $bg-gray;
}

.clicked_container {
    -webkit-box-shadow: 0 0 0 2px $border-blue;
    -moz-box-shadow: 0 0 0 2px $border-blue;
    box-shadow: 0 0 0 2px $border-blue;
}

.card_main_content {
    display: flex;
    flex-direction: column;
}

.card_main_box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.question_txt {
    font-size: 1.15em;
    color: $font-light-blue;
    margin-bottom: 0.2em;
}

.price_txt, .price_txt_complete {
    margin-left: 0.5em;
    font-weight: 600;
}

.price_txt {
    color: $font_blue;
}

.price_txt_complete {
    color: $font-completed;
}

.question_title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 1.45em;
    font-weight: 600;
    color: $font_blue;
    overflow-y: hidden;
}

.expiry_box {
    display: flex;
    margin: 0.3em 0;
    &__expiry_txt {
        margin: auto 0;
        margin-left: 1em;
    }
}

.remaining_time_arrrow_box {
    display: flex;
}

.remaing_time_box {
    margin: auto 1.5vw;
    font-size: 1.2em;
    color: $font-light-blue;
    font-weight: 500;
}

.arrow_box {
    margin: auto 0;
    transform: scale(1.3);
    color: $font-light-blue;
}

.arrow_icon {
    margin-top: 0.2em;
}

@include breakpoint(lg){
    .question_title {
        font-size: 1.9vw;
    }
    .expiry_box {
        &__expiry_txt {
            font-size: 1.2vw;
        }
    }
    .remaing_time_box {
        font-size: 1.6vw;
    }
}

@include breakpoint(md){
    .question_title {
        font-size: 2vw;
    }
    .question_txt {
        font-size: 1em;
    }
    .remaing_time_box {
        margin: auto 1vw;
        font-size: 1.4vw;
    }
    .arrow_box {
        transform: scale(1);
    }
}

// job search
.search_job_container {
    width: 47.5%;
    display: flex;
    align-items: center;
    background: $bg-white;
    margin: 0.5em 0.5em;
    padding: 0.5em 0.8em;
    border-radius: $borderRadius;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search_job_container:hover {
    background-color: $bg-gray;
}

.clicked_job_container {
    width: 97%;
    display: flex;
    background: $bg-white;
    margin: 0.5em 0.5em;
    padding: 0.5em 0.8em;
    border-radius: $borderRadius;
    &:first-child {
        -webkit-box-shadow: 0 0 0 2px $border-blue;
        -moz-box-shadow: 0 0 0 2px $border-blue;
        box-shadow: 0 0 0 2px $border-blue;
    }
    cursor: pointer;
    transition: background-color 0.3s;
}

.clicked_job_container:hover {
    background-color: $bg-gray;
}

.content_box {
    width: 97%;
}

@include breakpoint(md){
    .search_job_container {
        margin: 0.8vw 0.5vw;
    }
}

// MyQuestions
.question_price_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title_box {
        font-size: 1.15em;
        color: $font-light-blue;
    }
}

.status_green,
.status_yellow,
.status_red {
    padding: 0.2em 0.7em;
    font-size: 1em;
    border-radius: 5px;
    font-weight: 500;
}

.status_green {
    color: $font-completed;
    background: $bg-completed;
}

.status_yellow {
    color: $font-active;
    background: $bg-active;
}

.status_red {
    color: $font-cancelled;
    background: $bg-cancelled;
}

@include breakpoint(lg) {
    .status_green,
    .status_yellow,
    .status_red {
        font-size: 1.2vw;
    }
}

@include breakpoint(md) {
    .question_price_box{
        &__title_box {
            font-size: 1em;
        }
    }
}