@import '../../assets/styles/GlobalStyle/global_variables.scss';

.question_card_container {
    width: 97%;
    background: $bg-white;
    margin: 0.8em 0;
    padding: 0.5em 0.8em;
    border-radius: $borderRadius;
}

.detail_container {
    width: 98%;
    height: 100%;
}

.image_container {
    width: 8em;
    height: 8em;
    padding: 0 0.5em;
}

.document_container {
    width: 98%;
    height: 4em;
    margin: 0.3em auto;
}

.template_container {
    width: 98%;
    height: 10em;
    margin: 0 auto;
}

.template_card_container {
    width: 97%;
    background: $bg-white;
    margin-bottom: 1em;
    padding: 0 0.5em;
    border-radius: $borderRadius;
}

.tempalte_detail_container {
    width: 100%;
    height: 100%;
    padding: 0 1em;
}

.professionals_container {
    width: 91%;
    padding: 1em;
    margin: 0.4em 1em;
    background: $bg-dashboard;
    border-radius: $borderRadius;
    display: flex;
    &__icon {
        margin-right: 1em;
    }
    &__profile {
        width: 20em;
    }
}

.appointment_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.history_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.calendar_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile_container {
    width: 100%;
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template_skeleton {
    width: 98%;
    height: 3em;
    margin: 0.4em auto;
}

.card_flex_box {
    display: flex;
}

.flex_box {
    display: flex;
    padding: 1em;
}

.max_width {
    margin-left: 1em;
    width: 80%;
}

.margin_top {
    margin-top: 1em;
}

.margin_right {
    margin-right: 0.5em;
}