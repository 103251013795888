@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.category_state_box {
    width: 100%;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    display: flex;
    &__country_box, &__category_box {
        padding: 0.3em 0.5em;
        border-radius: 5px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__country_box {
        color: $font-country;
        background: $bg-country;
        margin-right: 1em;
    }
    &__category_box {
        color: $font-category;
        background: $bg-category;
    }
    &__icons {
        margin-right: 0.3em;
    }
}

@include breakpoint(md){
    .category_state_box {
        margin: 0.5vw 0;
        &__icons {
            margin-right: 0.3vw;
        }
        &__country_box, &__category_box {
            padding: 0.2em 0.5em;
            & p {
                font-size: 1.2vw;
                margin: auto 0;
            }
        }
        &__country_box {
            margin-right: 0.5em;
        }
        &__icons {
            transform: scale(0.8);
        }
    }
}