@import '../../../assets/styles/GlobalStyle/global_variables.scss';

.subtitle_box, 
.subtitle_box_top {
    display: flex;
    width: 100%;
    margin-bottom: 1em;
    align-items: center;
}

.subtitle_box {
    margin-top: 1.5em;
}

.subtitle_box_top {
    margin-top: 0;
}

.subtitle_icon {
    transform: scale(1.2);
}

.subtitle_title {
    color: $font-blue;
    font-weight: 700;
    font-size: 1.2em;
    margin-left: 0.5em;
}

@include breakpoint(lg){
    .subtitle_icon {
        transform: scale(1);
    }
    
    .subtitle_title {
        font-size: 1em;
    }
}

@include breakpoint(sm){
    .subtitle_icon {
        transform: scale(1);
    }
    
    .subtitle_title {
        font-size: 0.8em;
    }
}