@import '../../../../../assets/styles/GlobalStyle/global_variables.scss';

.modal_title_box {
    position: relative;
    display: flex;
    width: 100%;
    padding: 1em;
    margin-bottom: 1em;
    justify-content: flex-start;
    align-items: center;
    &__icon {
        transform: scale(1.9);
        fill: $close-icon;
        cursor: pointer;
    }
    &__title {
        font-size: 1.4em;
        color: $font_blue;
        font-weight: 700;
        margin-left: 1em;
    }
}

.payment_method_list_box {
    width: 100%;
    max-height: 25em;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1em;
    &__card {
        margin-bottom: 1em;
    }
}

.btn_box {
    width: 90%;
    margin: 0 auto;
    padding: 3em 0 2em 0;
    .tfa_button_container1 {
        height: 3em;
        background: $btn-green;
        &:hover {
            background: $btn-green;
        }
    }
}